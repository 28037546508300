import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    minWidth: '128px',
    borderRadius: '5px',
    margin: '0px 20px',
    height: '41px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${colors.primaryOrange}`,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
    '&:active': {
      opacity: 0.3,
    },
    '@media (max-width: 1300px)': {
      display: 'none',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: colors.primaryOrange,
  },
  dot: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    background: colors.primaryOrange,
    marginLeft: '7px',
  },
})
