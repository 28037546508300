import { Button, IconButton, Link } from '@material-ui/core'
import headerOuterLinkArrow from 'assets/icons/headerOuterLinkArrowLight.svg'
import primaryDaisyLogo from 'assets/icons/primaryDaisyLogo.svg'
import { PendingButton } from 'components/buttons/pendingButton'
import { WalletButton } from 'components/buttons/walletButton'
import { TransactionListModal } from 'components/modals/transactionList'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, NavLink } from 'react-router-dom'
import { CLAIM, EXCHANGE, LIQUID_APP } from 'routes'
import { useTronWeb } from 'services/AccountService'
import { useTransactionService } from 'services/TransactionService'
import { useStyles } from './styles'

export const Header = (): JSX.Element => {
  const { chain, account, isLoggedIn, tryOpenTronAuthWindow } = useTronWeb()
  const classes = useStyles()
  const { t } = useTranslation()
  const { hasPendingTransactions } = useTransactionService()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)

  const navLinksInfo: { title: string; target: string; isOuter: boolean }[] = [
    { target: CLAIM, title: 'header_claim', isOuter: false },
    { target: EXCHANGE, title: 'header_exchange', isOuter: false },
    { target: LIQUID_APP, title: 'header_liquid_promo', isOuter: false },
  ]
  let navLinks: JSX.Element[] = []
  if (!!account) {
    navLinks = navLinksInfo.map(({ title, target, isOuter }) =>
      isOuter ? (
        <Link href={target} key={target} target="_blank" className={classes.navLinkContainer}>
          {t(title)}
          <img className={classes.outerArrow} src={headerOuterLinkArrow} alt="header outer link" />
        </Link>
      ) : (
        <li className={classes.liItem} key={target}>
          <NavLink to={target} className={classes.navLinkContainer} activeClassName={classes.navLinkActiveContainer}>
            <span>{t(title)}</span>
          </NavLink>
        </li>
      ),
    )
  }

  return (
    <header className={classes.root}>
      <div className={classes.maxWidth}>
        <IconButton>
          <RouterLink to={CLAIM}>
            <img src={primaryDaisyLogo} alt="daisy logo icon" />
          </RouterLink>
        </IconButton>
      </div>

      <nav className={classes.navBarContainer}>
        <ul className={classes.ulList}>{navLinks}</ul>
      </nav>

      <div className={classes.maxWidth}>
        <div className={classes.row}>
          {hasPendingTransactions() && <PendingButton onClick={handleOpen} />}
          {!!account ? (
            <WalletButton onClick={handleOpen} />
          ) : (
            isLoggedIn && (
              <Button classes={{ root: classes.unConnectedButton }} onClick={tryOpenTronAuthWindow}>
                {t('header_connect_wallet')}
              </Button>
            )
          )}
        </div>
      </div>
      {account && chain && <TransactionListModal handleClose={handleClose} open={openModal} chain={chain} />}
    </header>
  )
}
