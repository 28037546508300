import { FEE_LIMIT } from 'assets/constants'
import { LockupStatsInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { parseOldLockupInfo } from 'core/parsers/lockupInfoParserLiquid'
import { TronWeb } from 'tronweb-typings'
import { decodeParams } from 'utils/tron/decodeUtils'
import { RequestDelayUtils } from 'utils/tron/RequestDelayUtils'
import { TronWebAPI } from './TronAPI'

async function lockupInfo(
  tronWeb: TronWeb,
  contractAddress: string,
  account: string,
  firstDay: number,
  lastDay: number,
): Promise<LockupStatsInfo> {
  await RequestDelayUtils.addDelay()
  const functionSelector = 'lockupInfo(address,uint8,uint8)'
  const parameters = [
    { type: 'address', value: account },
    { type: 'uint8', value: firstDay },
    { type: 'uint8', value: lastDay },
  ]
  const lockupInfoData = await TronWebAPI.callMethod(tronWeb, contractAddress, FEE_LIMIT, functionSelector, parameters)

  const types =
    'tuple(uint256,uint256,uint256,uint256,uint256,uint256,uint64[],uint32[],uint16[],uint32,uint32,uint32,uint256,tuple(uint32,uint128)[])'
  const decodedLockupInfo = decodeParams([types], '0x' + lockupInfoData, false)
  return parseOldLockupInfo(decodedLockupInfo)
}

export const PrivatePresaleLockupAPI = { lockupInfo }
