import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  poolsCardContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    marginBottom: '30px',
    '@media (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  poolCard: {
    minHeight: '150px',
    minWidth: '200px',
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
})
