import { DAISY_PRECISION } from 'assets/constants'
import daisyTokenGreen from 'assets/icons/daisyTokenGreen.svg'
import { Trans } from 'react-i18next'
import { formattedDecimals } from 'utils/tsUtils'
import { useStyles } from './styles'
import { CardProps } from 'core/interfaces/components/cardHeader/CardProps'

export const CardHeader = ({ amount }: CardProps): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      <span className={classes.title}>
        <Trans
          i18nKey="claim_page_total_vested_daisy"
          components={[<span className={classes.primaryTitle} key="0" />]}
        />
      </span>
      <div className={classes.mainContainer}>
        <div className={classes.row}>
          <span className={classes.primaryTitle}>{formattedDecimals(amount, DAISY_PRECISION)}</span>
          <img src={daisyTokenGreen} alt="daisyTokenGreen.svg" />
        </div>
      </div>
    </>
  )
}
