export const PresaleWithdrawController = [
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'pools_',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: 'totalsInToken_',
        type: 'uint256[]',
      },
    ],
    name: 'infoByTotal',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'totalInToken',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'unvestedInToken',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'withdrawableOf',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'linkToList',
            type: 'string',
          },
          {
            internalType: 'bytes32',
            name: 'root',
            type: 'bytes32',
          },
          {
            internalType: 'uint64[]',
            name: 'cliffEnd',
            type: 'uint64[]',
          },
          {
            internalType: 'uint32[]',
            name: 'vestingDuration',
            type: 'uint32[]',
          },
          {
            internalType: 'uint16[]',
            name: 'percentageOfTotal',
            type: 'uint16[]',
          },
        ],
        internalType: 'struct IPool.LockupInfo[]',
        name: 'lockupInfo',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'pools_',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: 'totalsInToken_',
        type: 'uint256[]',
      },
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
    ],
    name: 'infoByTotalForAccount',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'totalInToken',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'unvestedInToken',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'withdrawableOf',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'linkToList',
            type: 'string',
          },
          {
            internalType: 'bytes32',
            name: 'root',
            type: 'bytes32',
          },
          {
            internalType: 'uint64[]',
            name: 'cliffEnd',
            type: 'uint64[]',
          },
          {
            internalType: 'uint32[]',
            name: 'vestingDuration',
            type: 'uint32[]',
          },
          {
            internalType: 'uint16[]',
            name: 'percentageOfTotal',
            type: 'uint16[]',
          },
        ],
        internalType: 'struct IPool.LockupInfo[]',
        name: 'lockupInfo',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account_',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'oldPools_',
        type: 'address[]',
      },
      {
        internalType: 'uint8[]',
        name: 'oldPoolsFirstDay_',
        type: 'uint8[]',
      },
      {
        internalType: 'uint8[]',
        name: 'oldPoolsLastDay_',
        type: 'uint8[]',
      },
      {
        internalType: 'address[]',
        name: 'newPools_',
        type: 'address[]',
      },
    ],
    name: 'infoOf',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'confirmedFromPresale',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'confirmedFromReferralPresale',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'unconfirmedFromPresale',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'unconfirmedFromReferralPresale',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'vestedAccountAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'availableAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint64[]',
            name: 'cliffEnd',
            type: 'uint64[]',
          },
          {
            internalType: 'uint32[]',
            name: 'vestingDuration',
            type: 'uint32[]',
          },
          {
            internalType: 'uint16[]',
            name: 'percentageOfTotal',
            type: 'uint16[]',
          },
          {
            internalType: 'uint32',
            name: 'startPromoTimestamp',
            type: 'uint32',
          },
          {
            internalType: 'uint32',
            name: 'endPromoTimestamp',
            type: 'uint32',
          },
          {
            internalType: 'uint32',
            name: 'unlockTokenTimestamp',
            type: 'uint32',
          },
          {
            internalType: 'uint256',
            name: 'promoPoolShares',
            type: 'uint256',
          },
          {
            components: [
              {
                internalType: 'uint32',
                name: 'totalUsers',
                type: 'uint32',
              },
              {
                internalType: 'uint128',
                name: 'totalLockedTokens',
                type: 'uint128',
              },
            ],
            internalType: 'struct IOldPool.Statistic[]',
            name: 'stats',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct IOldPool.LockupInfo[]',
        name: 'oldPoolLockupInfo',
        type: 'tuple[]',
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'totalInToken',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'unvestedInToken',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'withdrawableOf',
            type: 'uint256',
          },
          {
            internalType: 'string',
            name: 'linkToList',
            type: 'string',
          },
          {
            internalType: 'bytes32',
            name: 'root',
            type: 'bytes32',
          },
          {
            internalType: 'uint64[]',
            name: 'cliffEnd',
            type: 'uint64[]',
          },
          {
            internalType: 'uint32[]',
            name: 'vestingDuration',
            type: 'uint32[]',
          },
          {
            internalType: 'uint16[]',
            name: 'percentageOfTotal',
            type: 'uint16[]',
          },
        ],
        internalType: 'struct IPool.LockupInfo[]',
        name: 'newPoolLockupInfo',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId_',
        type: 'bytes4',
      },
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'oldPools_',
        type: 'address[]',
      },
      {
        internalType: 'address[]',
        name: 'newPools_',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: 'newPoolsTotalInToken_',
        type: 'uint256[]',
      },
      {
        internalType: 'bytes32[][]',
        name: 'newPoolsProofs_',
        type: 'bytes32[][]',
      },
    ],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]
