import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { useStyles } from './styles'

export const GlobalBigButtonSkeleton = (): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.contentContainer}>
      <Skeleton className={classes.primaryButton} />
    </div>
  )
}
