import { LockTokensInfoSkeleton } from '../lockTokensInfo'
import { PromoInfoSkeleton } from '../promoInfo'
import { useStyles } from './styles'

export const PromoPageSkeleton = (): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <PromoInfoSkeleton />
        <LockTokensInfoSkeleton />
      </div>
    </div>
  )
}
