/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FEE_LIMIT } from 'assets/constants'
import { PresaleWithdrawController } from 'contracts/abi/PresaleWithdrawController'
import { AdditionalPoolClaimInfo, LockupInfo, MerkleInfo, PoolInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { DecodedAdditionalPoolInfo, parsePoolsInfo } from 'core/parsers/lockupInfoParser'
import { arrayify, keccak256, solidityPack } from 'ethers/lib/utils'
import { fromHex, toHex } from 'tron-format-address'
import { TronWeb } from 'tronweb-typings'
import { decodeParams } from 'utils/decodeUtils'
import { MerkleTree } from 'utils/merkleTree'
import { RequestDelayUtils } from 'utils/tron/RequestDelayUtils'
import { fromBNish } from 'utils/tsUtils'
import { TronWebAPI } from './TronAPI'

export function getMerkleItemBuffer(item: { address: string; tokens: string }): Buffer {
  const packed = solidityPack(['address', 'uint'], [item.address, item.tokens])
  return Buffer.from(arrayify(keccak256(packed)))
}

export const ADDRESS_PREFIX_REGEX = /^(41)/

async function lockupInfo(
  tronWeb: TronWeb,
  contractAddress: string,
  account: string,
  presaleAddress: string,
  someAdditionalPoolAddress: [string, string, string, string],
  otherAdditionalPoolAddress: [string, string],
): Promise<LockupInfo> {
  const additionalPoolAddress = [...someAdditionalPoolAddress, ...otherAdditionalPoolAddress]
  await RequestDelayUtils.addDelay()

  const functionSelector = 'infoOf(address,address[],uint8[],uint8[],address[])'
  const parameters = [
    { type: 'address', value: account },
    { type: 'address[]', value: [presaleAddress] },
    { type: 'uint8[]', value: [0] },
    { type: 'uint8[]', value: [0] },
    { type: 'address[]', value: additionalPoolAddress },
  ]

  const infoData = await TronWebAPI.callMethod(tronWeb, contractAddress, FEE_LIMIT, functionSelector, parameters)
  const types = [
    'tuple(uint256,uint256,uint256,uint256,uint256,uint256,uint64[],uint32[],uint16[],uint32,uint32,uint32,uint256,tuple(uint32,uint128)[])[]',
    'tuple(uint256,uint256,uint256,string,bytes32,uint64[],uint32[],uint16[])[]',
  ]

  const response = decodeParams(types, '0x' + infoData, false)
  const result = parsePoolsInfo(response, presaleAddress, additionalPoolAddress)
  // if in additional pools total equal zero need to check it from related merkle tree, and update if it has difference
  const poolsForUpdate = await getRelatedPools([...result.additionalPools, ...result.otherAdditionalPools], account)

  try {
    await RequestDelayUtils.addDelay()
    const _pool = await TronWebAPI.callMethod(tronWeb, presaleAddress, FEE_LIMIT, 'pool()')
    const pool = fromHex(decodeParams(['address'], '0x' + _pool, false)[0].replace(ADDRESS_PREFIX_REGEX, '0x'))
    for (let i = 0; i < someAdditionalPoolAddress.length; i++) {
      await RequestDelayUtils.addDelay()
      const balanceSelector = 'balanceOfAccountInPool(address,address)'
      const balanceParameters = [
        { type: 'address', value: account },
        { type: 'address', value: someAdditionalPoolAddress[i] },
      ]
      const _locked = await TronWebAPI.callMethod(tronWeb, pool, FEE_LIMIT, balanceSelector, balanceParameters)
      result.additionalPools[i].locked = fromBNish(decodeParams(['uint256'], '0x' + _locked, false)[0])
    }
  } catch (e) {
    console.warn('contract not updated')
  }

  if (!poolsForUpdate.length) {
    return result
  }

  const addresses = poolsForUpdate.map((item) => item.poolInfo.poolAddress)
  const totals = poolsForUpdate.map((item) => item.proofInfo.accountItem.tokens)

  let infoByTotalData
  try {
    const infoByTotalFunctionSelector = 'infoByTotalForAccount(address[],uint256[],address)'
    const infoByTotalParameters = [
      { type: 'address[]', value: addresses },
      { type: 'uint256[]', value: totals },
      { type: 'address', value: account },
    ]
    infoByTotalData = await TronWebAPI.callMethod(
      tronWeb,
      contractAddress,
      FEE_LIMIT,
      infoByTotalFunctionSelector,
      infoByTotalParameters,
    )
  } catch (e) {
    const infoByTotalFunctionSelector = 'infoByTotal(address[],uint256[])'
    const infoByTotalParameters = [
      { type: 'address[]', value: addresses },
      { type: 'uint256[]', value: totals },
    ]
    infoByTotalData = await TronWebAPI.callMethod(
      tronWeb,
      contractAddress,
      FEE_LIMIT,
      infoByTotalFunctionSelector,
      infoByTotalParameters,
    )
  }

  const infoByTotalTypes = 'tuple(uint256,uint256,uint256,string,bytes32,uint64[],uint32[],uint16[])[]'
  const [updatedInfo]: [DecodedAdditionalPoolInfo[]] = decodeParams([infoByTotalTypes], '0x' + infoByTotalData, false)

  updatedInfo.forEach((item, index) => {
    poolsForUpdate[index].poolInfo.proof = poolsForUpdate[index].proofInfo.proof
    poolsForUpdate[index].poolInfo.total = fromBNish(item[0])
    const availableToClaim = fromBNish(item[2])
    poolsForUpdate[index].poolInfo.availableToClaim = availableToClaim
    result.availableToClaimAmount = result.availableToClaimAmount.add(availableToClaim)
  })

  return result
}

async function withdraw(
  tronWeb: any,
  account: string,
  contractAddress: string,
  presaleAddress: [string] | [],
  additionalPoolsInfo: AdditionalPoolClaimInfo[],
): Promise<string> {
  const additionalPoolAddress = additionalPoolsInfo.map(({ address }) => address)
  const totals = additionalPoolsInfo.map(({ total }) => total)
  const proofs = (await Promise.all(
    additionalPoolsInfo.map(
      async (poolInfo) => poolInfo.proof || (await fetchProof(poolInfo.merkleInfoLink, account))?.proof,
    ),
  )) as string[][]
  await RequestDelayUtils.addDelay()
  const contract = await tronWeb.contract(PresaleWithdrawController, contractAddress)
  return await contract.withdraw(presaleAddress, additionalPoolAddress, totals, proofs).send({ feeLimit: FEE_LIMIT })
}

type ProofInfo = { proof: string[]; accountItem: { address: string; tokens: string } }
type RelatedPool = { proofInfo: ProofInfo; poolInfo: PoolInfo }

async function getRelatedPools(additionalPools: PoolInfo[], account: string): Promise<RelatedPool[]> {
  const zeroTotals = additionalPools.filter(({ total }) => total.isZero())
  const data = await Promise.all(
    zeroTotals.map(async (poolInfo) => ({ poolInfo, proofInfo: await fetchProof(poolInfo.merkleInfoLink, account) })),
  )
  return data.filter(({ proofInfo }) => !!proofInfo) as RelatedPool[]
}

async function fetchProof(link: string, account: string): Promise<ProofInfo | undefined> {
  const response = await fetch(link)
  const merkleInfo: MerkleInfo = await response.json()
  const hexedAccount = toHex(account)
  const accountItem = merkleInfo.data.find((item) => item.address.toLowerCase() === hexedAccount)
  if (!accountItem) {
    return
  }

  const packed = merkleInfo.data.map(getMerkleItemBuffer)
  const tree = new MerkleTree(packed)
  const proof = tree.getHexProof(getMerkleItemBuffer(accountItem))
  return { proof, accountItem }
}

export const PresaleLockupAPI = { lockupInfo, withdraw }
