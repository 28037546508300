import { fromBNish, fromDecimals } from 'utils/tsUtils'

//COMMON

export const LIQUID_MINING = 'https://liquidmining.com/data-center?content-key=coins'
export const HOW_TO_CLAIM =
  'https://www.youtube.com/watch?v=GgPa0ZKRF7A&list=PLd_z5htVLWvirAVwSlEW4GlBZp6G69iai&index=3'
export const DAISY_APP = 'https://app.daisydao.io/'
export const UPLIFT_BRIDGE = 'https://app.uplift.io/bridge'

//TRANSACTION
export const TRANSACTION_CACHE_LIMITATION_COUNT = 1000
export const ADDRESS_PREFIX = '41'
export const LOCAL_STORAGE_VERSION = 1.31
export const DAISY_PRECISION = 18
export const USDT_PRECISION = 6
export const TRX_PRECISION = 6
export const MAX_INT_VALUE = fromDecimals(Number.MAX_SAFE_INTEGER.toFixed(), DAISY_PRECISION)
export const MIN_TRX_CLAIM = fromBNish('100000000')
export const FEE_LIMIT = `700000000`
export const CONFIRM_FEE_LIMIT = `1000000000`
export const DECLINED_BY_USER = 'Confirmation declined by user'
export const TRON_WEB_ENVIRONMENTS_NOT_FOUND = 'TronWeb environments not found'
export const NOT_PROVIDED_STRICT_CONTEXT = 'Not provided strict context'
export const CANCEL_PROMISE = 'Cancel Promise'
export const ADDRESS_PREFIX_REGEX = /^(41)/

//EXCHANGE
export const MIN_TRX_SWAP = fromBNish('40000000')
export const MIN_TRX_APPROVE = fromBNish('15000000')

//SOCIAL LINKS
export const TELEGRAM_LINK = 'https://t.me/daisy_family'
export const TWITTER_LINK = 'https://twitter.com/DaisyProtocol'
export const MEDIUM_LINK = 'https://medium.com/daisy-protocol'

//FOOTER LINKS
export const TERMS_OF_USE = 'https://drive.google.com/file/d/1PFMJpUWiCv8uachmVwuf7pD5TdMJOwRx/view?usp=sharing'

//TUTORIAL LINKS
export const CLAIM_EXCHANGE_STAKE_YOU_TUBE_GUIDE = 'https://www.youtube.com/watch?v=naRNmv7tqqk&ab_channel=Bitversity'
export const CLAIM_EXCHANGE_STAKE_DOC_GUIDE = process.env.PUBLIC_URL + '/bridgeGuide.pdf'
