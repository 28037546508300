import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    background: colors.primaryDarkBlue,
    borderRadius: '30px',
    width: '100%',
  },
  content: {
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: colors.secondWhite,
    fontSize: '1.3rem',
    fontWeight: 500,
    marginBottom: 'auto',
  },
  primaryInfoText: {
    color: colors.secondaryLightGrey,
  },
  secondaryInfoText: {
    color: colors.primaryGreen,
  },
  primaryAmountText: {
    color: colors.primaryGreen,
    fontSize: '1.3rem',
  },
  secondaryAmountText: {
    color: colors.thirdLightGrey,
    fontSize: '1.3rem',
  },
  separator: {
    borderTop: `1px solid ${colors.thirdLightGrey}`,
    width: '100%',
    height: '2px',
  },
})
