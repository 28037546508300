import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputContainer: {
    height: '82px',
    width: '100%',
    maxWidth: '590px',
    margin: '20px 0px 13px',
    padding: '0px 20px',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'row',
    borderRadius: '30px',
    background: colors.secondaryDarkGrey,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    color: colors.white,
    border: 'none',
    outline: 'none',
    overflow: 'hidden',
    fontSize: '1.7rem',
    textOverflow: 'ellipsis',
    backgroundColor: colors.transparent,
    lineHeight: '56px',
    '&:: placeholder': {
      color: colors.white,
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '-webkit-fill-available',
    marginBottom: '40px',
    '@media only screen and (max-width: 1439px)': {
      flexWrap: 'wrap',
    },
    '@media only screen and (max-width: 500px)': {
      padding: '0px',
      gap: '10px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '@media only screen and (min-width: 501px)': {
      justifyContent: 'center',
      gap: '6px',
    },
  },
  maxContainer: {
    boxShadow: `0px 4px 10px ${colors.black_opacity_0_45}`,
    borderRadius: '67px',
    height: '38px',
    maxWidth: '87px',
    minWidth: '75px',
    color: colors.white,
    background: colors.primaryPurple,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:not([data-disabled="true"]):hover': {
      opacity: '0.4',
    },
  },
  maxContainerMobile: {
    display: 'none',
    '@media only screen and (max-width: 600px)': {
      boxShadow: `0px 4px 10px ${colors.black_opacity_0_45}`,
      borderRadius: '20px',
      width: '90px',
      height: '60px',
      color: colors.white,
      background: colors.primaryPurple,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      ' &:hover': {
        opacity: '0.4',
      },
    },
  },
  maxLabelMobile: {
    display: 'none',
    '@media only screen and (max-width: 600px)': {
      display: 'flex',
    },
  },
})
