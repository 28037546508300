import { LockupStatsInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { formattedPercentFromContract } from 'utils/percentUtils'
import { BN, fromBNish } from 'utils/tsUtils'

const ONE_HUNDRED_PERCENT_BP = 10000

export function sharesPoolPercent(lockupInfo: LockupStatsInfo, userShares: BN): number {
  const totalShares: BN = lockupInfo.stats.reduce(
    (prevValue, currentValue) => prevValue.add(currentValue.totalLockedTokens),
    fromBNish('0'),
  )

  if (userShares.isZero() || totalShares.isZero()) {
    return 0
  }

  const userPercent = +userShares.mul(ONE_HUNDRED_PERCENT_BP).mul(ONE_HUNDRED_PERCENT_BP).div(totalShares)
  return formattedPercentFromContract(userPercent) / ONE_HUNDRED_PERCENT_BP
}
