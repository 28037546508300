import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CallMadeIcon from '@material-ui/icons/CallMade'
import { tronSets } from 'contracts/sets/sets'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTransactionService } from 'services/TransactionService'
import { useStyles } from './styles'
import { actionToText } from './transactionUtils'
import { ChainEnum } from 'core/enums/services/ChainEnum'

type ModalProps = {
  handleClose: () => void
  open: boolean
  chain: ChainEnum
}

export const TransactionListModal = ({ handleClose, open, chain }: ModalProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { clearTransactions, filterTransactions } = useTransactionService()

  const pendingTransactions = filterTransactions({ statuses: ['unknown'] })
  const previousTransactions = filterTransactions({ statuses: ['success', 'error'] })

  const openExplorer = (txHash: string) => window.open(`${tronSets[chain].transactionLink}${txHash}`)

  const transactionList = [
    { title: t('transaction_modal_pending_transaction'), list: pendingTransactions.reverse() },
    { title: t('transaction_modal_previous_transactions'), list: previousTransactions.reverse() },
  ]

  return (
    <Dialog classes={{ paperWidthSm: classes.paperWidthSm }} maxWidth="sm" onClose={handleClose} open={open}>
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography classes={{ root: classes.primaryTitle }}>{t('transaction_modal_transaction_submitted')}</Typography>
      </MuiDialogTitle>

      {transactionList.map((sections, index) => (
        <div key={index}>
          {sections.list.length !== 0 && (
            <MuiDialogContent classes={{ root: classes.column }}>
              <div>
                <span className={classes.secondaryTitle}>{sections.title}</span>
                <div className={classes.dialogContent}>
                  <div className={classes.scrollContainer}>
                    {sections.list.map((tx, index) => (
                      <div className={classes.row} key={index} onClick={() => openExplorer(tx.hash)}>
                        <span className={classes.primaryInfo}>{actionToText(t, tx.action)}</span>
                        <CallMadeIcon fontSize="small" className={classes.imageColor} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </MuiDialogContent>
          )}
        </div>
      ))}
      {(previousTransactions.length !== 0 || pendingTransactions.length !== 0) && (
        <div className={classes.onClickTextContainer} onClick={clearTransactions}>
          <span className={classes.onClickText}>{t('transaction_modal_clear_history')}</span>
        </div>
      )}
    </Dialog>
  )
}
