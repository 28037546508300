import { DAISY_PRECISION, HOW_TO_CLAIM, LIQUID_MINING } from 'assets/constants'
import triangle from 'assets/icons/triangle.svg'
import triangleAttention from 'assets/icons/triangleAttention.svg'
import { CommonCard } from 'components/commonCard'
import { useStrictLockupInfo } from 'hooks/strict/lockupInfo/useStrictlockupInfo'
import { useStrictLiquidLockupInfo } from 'hooks/strict/privatePresaleLockup/useStrictLockupInfo'
import { Trans, useTranslation } from 'react-i18next'
import { sharesPoolPercent } from 'utils/liquidPromo/promoPoolPercent'
import { alreadyLockSum } from 'utils/liquidPromo/yourAlreadyLock'
import { BN, formattedDecimals } from 'utils/tsUtils'
import { AmountWithLogo } from '../amountWithLogo'
import { useStyles } from './styles'

export const LockTokensInfo = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const lockupInfo = useStrictLiquidLockupInfo()
  const lock = useStrictLockupInfo()

  const userPercent = (userShares: BN) => {
    const percent = sharesPoolPercent(lockupInfo, userShares)
    switch (true) {
      case percent === 0:
        return '0'
      case percent > 0.01:
        return percent.toFixed(2)
      default:
        return '< 0.01'
    }
  }

  const { sum, additionalPoolsSum } = alreadyLockSum(lock)
  const formattedYouAlreadyLock = formattedDecimals(sum, DAISY_PRECISION)
  const percentYouAlreadyLock = userPercent(lock.promoPoolShares.add(additionalPoolsSum))

  return (
    <CommonCard>
      <div className={classes.content}>
        <span className={classes.title}>{t('promo_locked_tokens')}</span>
        <AmountWithLogo amount={formattedYouAlreadyLock} />

        <div className={classes.row}>
          <div className={classes.separator} />
          <img src={triangle} alt="triangle" />
          <div className={classes.separator} />
        </div>

        <span className={classes.title}>{t('promo_your_shares')}</span>
        <span className={classes.info}>{t('promo_percent_of_promo_pool', { percent: percentYouAlreadyLock })}</span>
      </div>

      <div className={classes.infoContent}>
        <img src={triangleAttention} alt="triangleAttention" />
        <span className={classes.boldTitle}>
          <Trans
            i18nKey="promo_rewards_claiming"
            components={[
              <a className={classes.boldInfo} href={LIQUID_MINING} target="_blank" rel="noreferrer" key="0" />,
            ]}
          />
        </span>
      </div>

      <div className={classes.сlaimInfoContent}>
        <span className={classes.boldTitle}>
          <Trans
            i18nKey="promo_how_to_claim_video"
            components={[
              <a className={classes.boldInfo} href={HOW_TO_CLAIM} target="_blank" rel="noreferrer" key="0" />,
            ]}
          />
        </span>
      </div>
    </CommonCard>
  )
}
