import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  card: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'row',
    gap: '26px',
    borderRadius: '30px',
    background: colors.primaryPurple,
    minWidth: '520px',
    maxWidth: '720px',
    padding: '24px 26px',
    marginBottom: '30px',
    boxSizing: 'border-box',
    '@media (max-width: 600px)': {
      minWidth: '100%',
      maxWidth: 'unset',
    },
  },
  primaryTitle: {
    color: colors.primaryGreen,
    fontSize: '1.29rem',
    fontWeight: 400,
  },
  secondaryTitle: {
    fontSize: '1rem',
    color: colors.white,
    margin: '4px 0px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
})
