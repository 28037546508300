import { makeStyles } from '@material-ui/core'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    background: colors.primaryDarkBlue,
    borderRadius: '30px',
    padding: '32px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    margin: '8px 0px',
  },
  info: {
    fontSize: '1.8rem',
    color: colors.primaryInfoText,

    '@media (max-width: 800px)': {
      fontSize: '1.4rem',
    },
  },
  title: {
    fontSize: '1.8rem',
    color: colors.secondaryLightGrey,

    '@media (max-width: 800px)': {
      fontSize: '1.4rem',
    },
  },
  separator: {
    borderTop: `1px solid ${colors.secondaryLightGrey}`,
    width: '100%',
  },
  boldTitle: {
    fontWeight: 400,
    fontSize: '1rem',
    color: colors.white,
  },
  boldInfo: {
    fontWeight: 400,
    fontSize: '1rem',
    color: colors.green_74_255_151,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  infoContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '18px',
    margin: '24px auto 0px',
  },
  сlaimInfoContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '18px',
    margin: '12px auto 0px',
  },
})
