import { CANCEL_PROMISE, DECLINED_BY_USER } from 'assets/constants'

export function isHandleableTransactionError(error: unknown): boolean {
  switch (true) {
    case error === DECLINED_BY_USER:
    case error === CANCEL_PROMISE:
      return false
    default:
      return true
  }
}
