import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  infoTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '720px',
    marginBottom: '30px',
    '@media (max-width: 600px)': {
      minWidth: '100%',
      maxWidth: 'unset',
    },
  },
  primaryInfoText: {
    fontSize: '1rem',
    fontWeight: 400,
    color: colors.white,
    marginBottom: '10px',
  },
  secondaryInfoText: {
    fontSize: '1rem',
    color: colors.white,
  },
})
