import { TronWebAPI } from 'api/tron/TronAPI'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import { useCallback } from 'react'
import { useTransactionService } from 'services/TransactionService'

export type RequestConfirm = (contractAddress: string, action: 'confirm' | 'referrerConfirm') => Promise<void>

export function useConfirmTokens(): RequestConfirm {
  const { tronWeb } = useStrictTron()
  const { registerTransaction, handleTransactionError } = useTransactionService()

  const request: RequestConfirm = async (contractAddress, action) => {
    return new Promise(async () => {
      try {
        const hash = await TronWebAPI.tryCompletePurchase(tronWeb, contractAddress)
        registerTransaction(hash, { action })
      } catch (error: unknown) {
        console.error('usePresaleConfirm', error)
        handleTransactionError(error)
      }
    })
  }
  return useCallback<RequestConfirm>(request, [tronWeb, registerTransaction])
}
