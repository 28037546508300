import { Button } from '@material-ui/core'
import { GlobalBigButtonWrapperTypeEnum } from 'core/enums/components/buttons/globalBigButton/GlobalBigButtonWrapperTypeEnum'
import { GlobalBigButtonProps } from 'core/interfaces/components/buttons/globalBigButton/GlobalBigButtonProps'
import React from 'react'
import { Link } from 'react-router-dom'
import { useStyles } from './styles'

export const GlobalBigButton = ({
  onClick,
  title,
  subTitle,
  errorMessage,
  wrapperType,
  link,
  isDisabled,
}: GlobalBigButtonProps): JSX.Element => {
  const classes = useStyles()
  wrapperType = wrapperType ?? GlobalBigButtonWrapperTypeEnum.USUAL_BUTTON

  const button = (
    <Button className={classes.primaryButton} onClick={onClick} disabled={isDisabled}>
      {errorMessage || (
        <div className={classes.column}>
          <span className={classes.label}>{title}</span>
          {subTitle && <span className={classes.subTitle}>{subTitle}</span>}
        </div>
      )}
    </Button>
  )

  const innerLinkButton = (
    <Link to={link ?? ''} className={classes.link}>
      {button}
    </Link>
  )

  const outerLinkButton = (
    <a className={classes.link} href={link ?? ''} target="_blank" rel="noreferrer">
      {button}
    </a>
  )

  const buttonRender = () => {
    switch (wrapperType) {
      case GlobalBigButtonWrapperTypeEnum.INNER_LINK:
        return link ? innerLinkButton : button
      case GlobalBigButtonWrapperTypeEnum.OUTER_LINK:
        return link ? outerLinkButton : button
      case GlobalBigButtonWrapperTypeEnum.USUAL_BUTTON:
      default:
        return button
    }
  }

  return <div className={classes.contentContainer}>{buttonRender()}</div>
}
