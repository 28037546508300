import { WithChildrenProps } from 'core/interfaces/hooks/strict/common/tron'
import { useLockupInfo } from 'hooks/claim/useLockupInfo'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import { PromoPageSkeleton } from 'pages/liquidPromo/skeletons/promoPage'
import { useEffect } from 'react'
import { useRefresh } from 'services/RefreshContextProvider'
import { LockupInfoStrictContextProvider } from 'services/strict/lockupInfo/lockupInfo'

export const LockupInfoWrapper = ({ children }: WithChildrenProps): JSX.Element => {
  const { chainId, account } = useStrictTron()
  const { fastRefresh } = useRefresh()
  const [lockupInfo, refreshLockupInfo] = useLockupInfo()

  useEffect(() => {
    refreshLockupInfo()
  }, [fastRefresh, account, chainId])

  if (!lockupInfo) {
    return <PromoPageSkeleton />
  }

  return <LockupInfoStrictContextProvider providedValue={lockupInfo}>{children}</LockupInfoStrictContextProvider>
}
