import { Banner } from 'components/banner'
import React from 'react'
import { useTronWeb } from 'services/AccountService'
import { WalletConnectionStatusEnum } from 'core/enums/services/WalletConnectionStatusEnum'

export const MainSwitch = (): JSX.Element => {
  const { tronWeb, account, chain, walletConnectionStatus } = useTronWeb()

  switch (true) {
    case !tronWeb:
      return <Banner transactionKey={'banners_connect_tronlink'} />
    case !account || walletConnectionStatus !== WalletConnectionStatusEnum.SUCCESS:
      return <Banner transactionKey={'banners_connect_wallet'} />
    case chain === undefined:
      return <Banner transactionKey={'banners_wrong_network'} />
    default:
      return <></>
  }
}
