import { LockupInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { StrictContextProps } from 'core/interfaces/hooks/strict/common/tron'
import React from 'react'

export const LockupInfoStrictContext = React.createContext<unknown>(undefined)

export const LockupInfoStrictContextProvider = ({
  children,
  providedValue,
}: StrictContextProps<LockupInfo>): JSX.Element => {
  return <LockupInfoStrictContext.Provider value={providedValue}>{children}</LockupInfoStrictContext.Provider>
}
