import { DAISY_PRECISION } from 'assets/constants'
import { ConfirmationButton } from 'components/buttons/confirmationButton'
import { GlobalBigButton } from 'components/buttons/globalBigButton'
import { CardHeader } from 'components/cardHeader'
import { tronSets } from 'contracts/sets/sets'
import { ButtonStateEnum } from 'core/enums/components/buttons/mainButton/ButtonStateEnum'
import { useConfirmTokens } from 'hooks/claim/usePresaleConfirm'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import { useStrictTrxBalance } from 'hooks/strict/common/useStrictTrxBalance'
import { useStrictLockupInfo } from 'hooks/strict/lockupInfo/useStrictlockupInfo'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTransactionService } from 'services/TransactionService'
import { formattedDecimals } from 'utils/tsUtils'
import { confirmStateErrorMessage, confirmValidateState } from './confirmUtils'
import { useStyles } from './styles'

export const TronConfirm = (): JSX.Element => {
  const classes = useStyles()
  const { chainId } = useStrictTron()
  const balanceInTRX = useStrictTrxBalance()
  const { mainPool, notConfirmedTokens, notConfirmedReferralTokens } = useStrictLockupInfo()

  const { t } = useTranslation()
  const { presaleReferral, presale } = tronSets[chainId]
  const { filterTransactions } = useTransactionService()
  const pendingTransactions = filterTransactions({ statuses: ['unknown'], actions: ['confirm', 'referrerConfirm'] })

  const presaleConfirmRequest = useConfirmTokens()
  const confirmErrorState = confirmValidateState(balanceInTRX)
  const confirmErrorMessage = confirmStateErrorMessage(confirmErrorState, t)

  let infoText
  let confirmState = ButtonStateEnum.UNKNOWN
  if (!notConfirmedTokens.isZero()) {
    confirmState = ButtonStateEnum.CONFIRM_PRESALE
    infoText = t('claim_page_unconfirmed_presale_token', {
      amount: formattedDecimals(notConfirmedTokens, DAISY_PRECISION),
    })
  } else if (!notConfirmedReferralTokens.isZero()) {
    confirmState = ButtonStateEnum.CONFIRM_REFERRER
    infoText = t('claim_page_unconfirmed_referrer_token', {
      amount: formattedDecimals(notConfirmedReferralTokens, DAISY_PRECISION),
    })
  }

  const onClick = useCallback(() => {
    switch (confirmState) {
      case ButtonStateEnum.CONFIRM_PRESALE:
        return presaleConfirmRequest(presale, 'confirm')
      case ButtonStateEnum.CONFIRM_REFERRER:
        return presaleConfirmRequest(presaleReferral, 'referrerConfirm')
    }
  }, [confirmState])

  const changeButton = () => {
    switch (true) {
      case pendingTransactions.length > 0:
        return <ConfirmationButton />
      default:
        return (
          <GlobalBigButton
            onClick={onClick}
            title={t('claim_page_confirmation_button')}
            errorMessage={confirmErrorMessage}
            isDisabled={!!confirmErrorMessage}
          />
        )
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.card}>
          <CardHeader amount={mainPool.total} />
          <div className={classes.primaryInfo}>{infoText}</div>
        </div>
        <div className={classes.buttonContainer}>{changeButton()}</div>
      </div>
    </div>
  )
}
