import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  skeleton: {
    minHeight: '124px',
    minWidth: '720px',
    marginBottom: '30px',
    '@media (max-width: 900px)': {
      minWidth: '520px',
    },
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
})
