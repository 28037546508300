import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  primaryButton: {
    minWidth: '320px',
    minHeight: '93px',
    fontSize: '1.715rem',
    color: colors.black,
    '@media (max-width: 600px)': {
      minWidth: '100%',
    },
    '&:hover': {
      backgroundColor: colors.primaryGreen,
    },
  },
  text: {
    marginLeft: '15px',
    fontSize: '1.715rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoContainer: {
    paddingTop: '10px',
    maxWidth: 'fit-content',
    textAlign: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 600px)': {
      minWidth: '100%',
    },
  },
})
