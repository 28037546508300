import { ThemeOptions } from '@material-ui/core'
import { SkeletonClassKey } from '@material-ui/lab/Skeleton'
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiSkeleton: SkeletonClassKey
  }
}

export const colors: { [key: string]: string } = {
  transparent: 'transparent',
  black: '#000000',
  white: '#ffffff',
  secondWhite: '#FAFAFA',
  red: '#FF5959',
  primaryOrange: '#FFC53A',
  primaryGreen: '#26F29D',
  primaryPurple: '#5271EC',
  secondaryPurple: '#5F5E7E',
  primaryDarkBlue: '#343350',
  primaryLightGrey: '#D0D0D0',
  secondaryLightGrey: '#B1B1B1',
  thirdLightGrey: '#A9A9A9',
  primaryDarkGrey: '#8C8C8C',
  secondaryDarkGrey: '#2C2C2C',
  primaryGradientBlue: '#000C49',
  secondGradientBlue: '#022889',
  primaryGold: '#FBC87C',
  green_74_255_151: '#4AFF97',

  black_opacity_0_25: 'rgb(0 0 0 / 25%)',
  black_opacity_0_45: 'rgba(0 0 0 / 45%)',
  black_opacity_0_55: 'rgba(0 0 0 / 55%)',
  grey_opacity_0_70: 'rgba(59 59 59 / 70%)',
  grey_light_opacity_0_30: 'rgba(214 214 214 / 30%)',
  secondaryBlackBG_opacity_50: 'rgba(46 46 46 / 50%)',
  primaryDarkGreen_opacity_30: 'rgba(38 242 157 / 30%)',
}

export const color = (c: string): string => colors[c]

export const themeConfig: ThemeOptions | undefined = {
  overrides: {
    MuiTypography: {
      root: {
        fontWeight: 300,
      },
      body1: {
        fontWeight: 300,
      },
      body2: {
        fontWeight: 300,
      },
    },
    MuiDialogContent: {
      root: {
        '&:first-child': { paddingTop: '0px' },
      },
    },
    MuiSlider: {
      root: {
        color: colors.primaryGreen,
      },
      mark: {
        height: '20px',
        marginTop: '-9px',
        backgroundColor: colors.primaryGreen,
      },
      markActive: {
        height: '20px',
        marginTop: '-9px',
        backgroundColor: colors.primaryGreen,
      },
      thumb: {
        color: colors.primaryGreen,
        width: '20px',
        height: '20px',
        marginTop: '-10px',
        marginLeft: '-8px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
    MuiLink: {
      root: {
        color: colors.white,
        fontSize: '1rem',
        fontFamily: 'Stolzl',
        fontStyle: 'normal',
        textDecoration: 'none',
        '&$underlineHover': {
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.primaryGreen,
      },
      colorSecondary: {
        color: colors.black,
      },
    },
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: colors.primaryGreen,
      },
      colorPrimary: {
        backgroundColor: colors.primaryOrange,
      },
      dashedColorPrimary: {
        backgroundColor: colors.primaryDarkGrey,
        backgroundImage: 'none',
      },
      dashed: {
        animation: 'none',
      },
      buffer: {
        height: '17px',
        borderRadius: '20px',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0px',
      },
    },
    MuiInputBase: {
      input: {
        underline: {
          border: 'none',
        },
        placeholder: {
          color: colors.primaryGreen,
          fontFamily: 'Stolzl',
          fortSize: '1rem',
        },
      },
    },
    MuiButton: {
      root: {
        fontStyle: 'normal',
        backgroundColor: colors.primaryGreen,
        color: colors.primaryBlue,
        borderRadius: '100px',
        textTransform: 'none',
        boxShadow: `0px 0px 5px ${colors.black_opacity_0_55}`,
        fontSize: '1rem',
        fontWeight: 300,
        '&:active': {
          backgroundColor: colors.primaryGreen,
          opacity: 0.3,
        },
        '&:hover': {
          backgroundColor: colors.primaryGreen,
          opacity: 0.6,
        },
        '&$disabled': {
          boxShadow: `0px 0px 5px ${colors.black_opacity_0_55}`,
          backgroundColor: colors.secondaryLightGrey,
          color: colors.black,
          borderRadius: '100px',
          '&:active': {
            backgroundColor: colors.secondaryLightGrey,
            opacity: 0.3,
          },
          '&:hover': {
            backgroundColor: colors.secondaryLightGrey,
            opacity: 0.6,
          },
        },
      },
    },
    MuiSkeleton: {
      root: {
        transform: 'none',
        backgroundColor: colors.grey_opacity_0_70,
        borderRadius: '30px',
      },
      text: {
        transform: 'none',
        borderRadius: '30px',
      },
    },
  },
}
