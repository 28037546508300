import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  card: {
    borderRadius: '30px',
    background: colors.black,
    minHeight: '90px',
    minWidth: '520px',
    maxWidth: '720px',
    padding: '33px 35px',
    marginBottom: '30px',
    boxSizing: 'border-box',
    '@media (max-width: 600px)': {
      minWidth: '100%',
      maxWidth: 'unset',
    },
  },
})
