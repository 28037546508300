import { PresaleLockupAPI } from 'api/tron/PresaleLockupAPI'
import { tronSets } from 'contracts/sets/sets'
import { LocalStorageKeysEnum } from 'core/enums/services/LocalStorageKeysEnum'
import { LockupInfo, PoolInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { Cached } from 'core/interfaces/utils/cache/cache'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import { useCallback, useState } from 'react'
import { getLocalStorageName } from 'utils/transformLocalStorage'
import { fromBNish, VoidPromiseCallback } from 'utils/tsUtils'
import useLocalStorage from '../useLocalStorage'

type LockupInfoCache = Record<string, Cached<LockupInfo | undefined>>

export function useLockupInfo(): [LockupInfo | undefined, VoidPromiseCallback] {
  const { tronWeb, account, chainId } = useStrictTron()
  const lockupInfoCacheKey = getLocalStorageName(LocalStorageKeysEnum.LOOKUP_INFO_CACHE)
  const [lockupInfoCache, setLockupInfoCache] = useLocalStorage<LockupInfoCache>(lockupInfoCacheKey, {})
  const wrappedLockupInfo = lockupInfoCache[`${chainId}${account}`]
  const _lockupInfo = wrappedLockupInfo ? unwrapLockupInfo(wrappedLockupInfo) : undefined
  const [lockupInfo, setLockupInfo] = useState<LockupInfo | undefined>(_lockupInfo)

  async function request() {
    try {
      const { withdrawController, unlock, additionalPools, otherAdditionalPools } = tronSets[chainId]
      const _lockupInfo = await PresaleLockupAPI.lockupInfo(
        tronWeb,
        withdrawController,
        account,
        unlock,
        additionalPools,
        otherAdditionalPools,
      )
      setLockupInfoCache({ ...lockupInfoCache, [`${chainId}${account}`]: wrapLockupInfo(_lockupInfo) })
      setLockupInfo(_lockupInfo)
    } catch (error) {
      console.error('useLockupInfo', error)
    }
  }

  const refreshLockupInfo = useCallback<VoidPromiseCallback>(request, [tronWeb, account, chainId, lockupInfoCache])
  return [lockupInfo, refreshLockupInfo]
}

function wrapLockupInfo(lockupInfo: LockupInfo): Cached<LockupInfo> {
  return {
    ...lockupInfo,
    mainPool: wrapPoolInfo(lockupInfo.mainPool),
    additionalPools: lockupInfo.additionalPools.map(wrapPoolInfo),
    otherAdditionalPools: lockupInfo.otherAdditionalPools.map(wrapPoolInfo),
    notConfirmedTokens: lockupInfo.notConfirmedTokens.toString(),
    notConfirmedReferralTokens: lockupInfo.notConfirmedReferralTokens.toString(),
    availableToClaimAmount: lockupInfo.availableToClaimAmount.toString(),
    promoPoolShares: lockupInfo.promoPoolShares.toString(),
  }
}

function wrapPoolInfo(poolInfo: PoolInfo): Cached<PoolInfo> {
  return {
    ...poolInfo,
    poolAddress: poolInfo.poolAddress,
    availableToClaim: poolInfo.availableToClaim.toString(),
    claimed: poolInfo.claimed.toString(),
    total: poolInfo.total.toString(),
    locked: poolInfo.locked.toString(),
  }
}

function unwrapLockupInfo(wrappedLockupInfo: Cached<LockupInfo>): LockupInfo {
  return {
    ...wrappedLockupInfo,
    mainPool: unwrapPoolInfo(wrappedLockupInfo.mainPool),
    additionalPools: wrappedLockupInfo.additionalPools.map(unwrapPoolInfo) as [PoolInfo, PoolInfo, PoolInfo, PoolInfo],
    otherAdditionalPools: wrappedLockupInfo.otherAdditionalPools.map(unwrapPoolInfo) as [PoolInfo, PoolInfo],
    notConfirmedTokens: fromBNish(wrappedLockupInfo.notConfirmedTokens),
    notConfirmedReferralTokens: fromBNish(wrappedLockupInfo.notConfirmedReferralTokens),
    availableToClaimAmount: fromBNish(wrappedLockupInfo.availableToClaimAmount),
    promoPoolShares: fromBNish(wrappedLockupInfo.promoPoolShares),
  }
}

function unwrapPoolInfo(poolInfo: Cached<PoolInfo>): PoolInfo {
  return {
    ...poolInfo,
    poolAddress: poolInfo.poolAddress,
    availableToClaim: fromBNish(poolInfo.availableToClaim),
    claimed: fromBNish(poolInfo.claimed),
    total: fromBNish(poolInfo.total),
    locked: fromBNish(poolInfo.locked),
  }
}
