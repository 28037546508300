import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'
import { PendingButtonProps } from 'core/interfaces/components/buttons/pendingButton/PendingButtonProps'

export const PendingButton = ({ onClick }: PendingButtonProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.buttonContainer}>
        <span className={classes.title}>{t('header_pending')}</span>
        <div className={classes.dot} />
        <div className={classes.dot} />
        <div className={classes.dot} />
      </div>
    </div>
  )
}
