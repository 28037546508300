import { Button, CircularProgress } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'

export const ConfirmationButton = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.contentContainer}>
      <Button className={classes.primaryButton}>
        <div className={classes.row}>
          <CircularProgress color={'secondary'} />
          <span className={classes.text}>{t('claim_page_confirmation')}</span>
        </div>
      </Button>
      <div className={classes.infoContainer}>
        <span>{t('claim_page_processing_information')}</span>
      </div>
    </div>
  )
}
