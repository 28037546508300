import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '41px',
    '&:active': {
      opacity: 0.3,
    },
    '&:hover': {
      opacity: 0.6,
    },
  },
  primaryButton: {
    minWidth: '160px',
    fontSize: '1rem',
    borderRadius: '50px',
    color: colors.primaryGreen,
    backgroundColor: colors.black,
    justifyContent: 'flex-start',
    height: '48px',
    marginRight: '-30px',
    '&:active': {
      backgroundColor: colors.black,
    },
    '&:hover': {
      backgroundColor: colors.black,
    },
    '@media (max-width: 600px)': {
      fontSize: '0.85rem',
      height: '100%',
    },
  },
  secondaryButton: {
    minWidth: '172px',
    fontSize: '1rem',
    borderRadius: '50px',
    height: '48px',
    justifyContent: 'space-around',
    '@media (max-width: 600px)': {
      fontSize: '0.85rem',
      height: '100%',
    },
  },
  imageMarginContainer: {
    margin: '0px 10px 0px 5px',
  },
  buttonLabel: {
    paddingRight: '30px',
  },
})
