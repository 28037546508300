import { StrictTronContext, StrictContextProps } from 'core/interfaces/hooks/strict/common/tron'
import React from 'react'

export const TronStrictContext = React.createContext<unknown>(undefined)

export const TronStrictContextProvider = ({
  children,
  providedValue,
}: StrictContextProps<StrictTronContext>): JSX.Element => {
  return <TronStrictContext.Provider value={providedValue}>{children}</TronStrictContext.Provider>
}
