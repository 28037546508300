import { PresaleLockupAPI } from 'api/tron/PresaleLockupAPI'
import { tronSets } from 'contracts/sets/sets'
import { AdditionalPoolClaimInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import { useStrictLockupInfo } from 'hooks/strict/lockupInfo/useStrictlockupInfo'
import { useCallback } from 'react'
import { useTransactionService } from 'services/TransactionService'

type WithdrawCallback = (mainPoolAddresses: [string] | []) => Promise<void>

export function useWithdraw(): WithdrawCallback {
  const { tronWeb, account, chainId } = useStrictTron()
  const { registerTransaction, handleTransactionError } = useTransactionService()

  const lockupInfo = useStrictLockupInfo()

  const request: WithdrawCallback = async (mainPoolAddresses) => {
    return new Promise(async () => {
      try {
        const { withdrawController } = tronSets[chainId]

        const additionalPoolsHash: AdditionalPoolClaimInfo[] = lockupInfo.additionalPools
          .filter(({ availableToClaim }) => !availableToClaim.isZero())
          .map(({ total, poolAddress, proof, merkleInfoLink }) => ({
            address: poolAddress,
            total,
            proof,
            merkleInfoLink,
          }))

        const otherAdditionalPoolsHash: AdditionalPoolClaimInfo[] = lockupInfo.otherAdditionalPools
          .filter(({ availableToClaim }) => !availableToClaim.isZero())
          .map(({ total, poolAddress, proof, merkleInfoLink }) => ({
            address: poolAddress,
            total,
            proof,
            merkleInfoLink,
          }))

        const hashPools: AdditionalPoolClaimInfo[] = additionalPoolsHash.concat(otherAdditionalPoolsHash)
        const hash = await PresaleLockupAPI.withdraw(tronWeb, account, withdrawController, mainPoolAddresses, hashPools)
        registerTransaction(hash, { action: 'claim', amount: lockupInfo.availableToClaimAmount })
      } catch (error: unknown) {
        console.error('useWithdraw', error)
        handleTransactionError(error)
      }
    })
  }
  return useCallback<WithdrawCallback>(request, [tronWeb, account, lockupInfo, registerTransaction])
}
