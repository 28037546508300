import { LinearProgress } from '@material-ui/core'
import { DAISY_PRECISION } from 'assets/constants'
import { ProgressBarProps } from 'core/interfaces/components/progressBar/props/ProgressBarProps'
import { useTranslation } from 'react-i18next'
import { progressBarPercent } from 'utils/percentUtils'
import { formattedDecimals } from 'utils/tsUtils'
import { useStyles } from './styles'

const MIN_WIDTH_PX = 8
export const ProgressBar = ({
  claimedAmount,
  availableToClaimAmount,
  totalAmount,
  hasLegend,
}: ProgressBarProps): JSX.Element => {
  const { t } = useTranslation()
  const {
    currentPercent: claimedCurrentPercent,
    formattedPercent: claimedFormattedPercent,
    lessThanOne: lessThanOneClaim,
  } = progressBarPercent(totalAmount, claimedAmount)
  const {
    currentPercent: unClaimedCurrentPercent,
    formattedPercent: unClaimedFormattedPercent,
    lessThanOne: lessThanOneUnClaim,
  } = progressBarPercent(totalAmount, availableToClaimAmount)

  //INFO: block width value in styled props
  const primaryWidth = claimedCurrentPercent
  const secondaryWidth = primaryWidth + unClaimedCurrentPercent
  const primaryGap = Math.max(primaryWidth, MIN_WIDTH_PX)
  const secondaryGap = Math.max(secondaryWidth, MIN_WIDTH_PX)

  const classes = useStyles({ primaryWidth, secondaryWidth, primaryGap, secondaryGap })

  return (
    <div className={classes.root}>
      {(lessThanOneClaim || claimedCurrentPercent !== 0) && (
        <div className={classes.primaryProgressLabel}>
          <span className={classes.primarySubTitle}>
            {lessThanOneClaim
              ? t('common_less_that_0_01')
              : t('common_percent_with_amount', { amount: claimedFormattedPercent })}
          </span>
        </div>
      )}
      <LinearProgress
        className={classes.progressBar}
        variant="buffer"
        value={primaryWidth}
        valueBuffer={secondaryWidth}
      />
      {!lessThanOneClaim && !lessThanOneUnClaim && claimedCurrentPercent == 0 && unClaimedCurrentPercent == 0 && (
        <div className={classes.zeroProgressLabel}>
          <span className={classes.primaryLabel}>{t('common_percent_with_amount', { amount: 0 })}</span>
        </div>
      )}

      {(lessThanOneUnClaim || unClaimedCurrentPercent !== 0) && (
        <div className={classes.secondaryProgressLabel}>
          <span className={classes.secondarySubTitle}>
            {lessThanOneUnClaim
              ? t('common_less_that_0_01')
              : t('common_percent_with_amount', { amount: unClaimedFormattedPercent })}
          </span>
        </div>
      )}

      {hasLegend ? (
        <div className={classes.row}>
          {(lessThanOneClaim || claimedCurrentPercent) !== 0 && (
            <div className={classes.primarySubTitleLabel}>
              <div className={classes.column}>
                <span className={classes.primarySubTitle}>{t('claim_page_claimed')}</span>
                <span className={classes.tertiarySubTitle}>
                  {t('common_daisy_with_amount', { amount: formattedDecimals(claimedAmount, DAISY_PRECISION) })}
                </span>
              </div>
            </div>
          )}

          {(lessThanOneUnClaim || unClaimedCurrentPercent) !== 0 && (
            <div className={classes.secondarySubTitleLabel}>
              <div className={classes.column}>
                <span className={classes.secondarySubTitle}>{t('claim_page_unclaimed')}</span>
                <span className={classes.tertiarySubTitle}>
                  {t('common_daisy_with_amount', {
                    amount: formattedDecimals(availableToClaimAmount, DAISY_PRECISION),
                  })}
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
