import { LockupInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { BN, fromBNish } from 'utils/tsUtils'

export function alreadyLockSum(lockupInfo: LockupInfo): {
  sum: BN
  additionalPoolsSum: BN
  otherAdditionalPoolsSum: BN
} {
  const additionalPoolsSum: BN = lockupInfo.additionalPools.reduce(
    (prevValue, currentValue) => prevValue.add(currentValue.locked),
    fromBNish('0'),
  )

  const otherAdditionalPoolsSum: BN = lockupInfo.otherAdditionalPools.reduce(
    (prevValue, currentValue) => prevValue.add(currentValue.locked),
    fromBNish('0'),
  )

  return {
    sum: additionalPoolsSum.add(otherAdditionalPoolsSum).add(lockupInfo.promoPoolShares),
    additionalPoolsSum,
    otherAdditionalPoolsSum,
  }
}
