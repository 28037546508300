import { Trc10ABI } from 'contracts/abi/TRC10'
import { BN, fromBNish } from 'utils/tsUtils'
import { TronWeb } from 'tronweb-typings'
import { RequestDelayUtils } from 'utils/tron/RequestDelayUtils'

async function allowance(tronWeb: TronWeb, contractAddress: string, account: string, contract: string): Promise<BN> {
  await RequestDelayUtils.addDelay()
  const response = await tronWeb.contract(Trc10ABI, contractAddress).allowance(account, contract).call()
  return fromBNish(response._isBigNumber ? response : response.remaining)
}

async function approve(
  tronWeb: TronWeb,
  contractAddress: string,
  spender: string,
  value: BN,
  message: { feeLimit: string },
): Promise<string> {
  await RequestDelayUtils.addDelay()
  return await tronWeb.contract(Trc10ABI, contractAddress).approve(spender, value).send(message)
}

async function balanceOf(tronWeb: TronWeb, contractAddress: string, account: string): Promise<BN> {
  await RequestDelayUtils.addDelay()
  const _balance: unknown = await tronWeb.contract(Trc10ABI, contractAddress).balanceOf(account).call()
  return fromBNish(`${_balance}`)
}

async function totalSupply(tronWeb: TronWeb, contractAddress: string): Promise<BN> {
  await RequestDelayUtils.addDelay()
  const _totalSupply: unknown = await tronWeb.contract(Trc10ABI, contractAddress).totalSupply().call()
  return fromBNish(`${_totalSupply}`)
}

export const TRC10API = {
  allowance: allowance,
  approve: approve,
  balanceOf: balanceOf,
  totalSupply: totalSupply,
}
