import { CLAIM_EXCHANGE_STAKE_DOC_GUIDE, CLAIM_EXCHANGE_STAKE_YOU_TUBE_GUIDE } from 'assets/constants'
import tutorialBook from 'assets/icons/tutorialBook.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'

export const TutorialBlock = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const tutorials = [
    { title: 'exchange_page_how_to_convert_tokens', link: CLAIM_EXCHANGE_STAKE_DOC_GUIDE },
    { title: 'common_youtube_guide', link: CLAIM_EXCHANGE_STAKE_YOU_TUBE_GUIDE },
  ]

  return (
    <div className={classes.card}>
      <img src={tutorialBook} alt="tutorialBook icon" />
      <div className={classes.column}>
        <span className={classes.primaryTitle}>{t('common_tutorials')}</span>

        <div className={classes.column}>
          {tutorials.map((item, index) => (
            <a
              key={index}
              href={item.link}
              className={classes.secondaryTitle}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(item.title)}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}
