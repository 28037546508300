import { Skeleton } from '@material-ui/lab'
import { useStyles } from './styles'

export const PoolsCardSkeleton = (): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.poolsCardContainer}>
      <Skeleton className={classes.poolCard} />
      <Skeleton className={classes.poolCard} />
      <Skeleton className={classes.poolCard} />
      <Skeleton className={classes.poolCard} />
      <Skeleton className={classes.poolCard} />
      <Skeleton className={classes.poolCard} />
    </div>
  )
}
