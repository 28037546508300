import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'
import liquidBanner from 'assets/icons/liquidBackground.png'

export const useStyles = makeStyles({
  root: {
    padding: '40px 0',
    '@media (max-width: 991px)': {
      marginTop: '40px',
      padding: '20px 0',
    },
    '@media (max-width: 767px)': {
      marginTop: '20px',
    },
  },
  bannerContainer: {
    display: 'grid',
    gridTemplateAreas: `'logo info'`,
    gridTemplateColumns: '1fr 1fr',
    gap: '33px',
    background: `linear-gradient(180deg, ${colors.primaryGradientBlue} 0.52%,  ${colors.secondGradientBlue} 100%)`,
    maxWidth: '720px',
    borderRadius: '30px',
    flexDirection: 'column',
    color: colors.white,
    textAlign: 'center',
    '@media (max-width: 800px)': {
      maxHeight: '200px',
      gap: '9px',
    },
  },
  logoContainer: {
    gridArea: 'logo',
    backgroundImage: `url(${liquidBanner})`,
    backgroundSize: 'cover',
    borderRadius: '30px 0px 0px 30px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 800px)': {
      maxHeight: '200px',
    },
  },
  infoContainer: {
    gridArea: 'info',
    padding: '20px 10px 20px 0px',
    display: 'grid',
    gridTemplateAreas: `'title' 'timer' 'bitcoin'`,
    gap: '30px',
    '@media (max-width: 800px)': {
      gap: '10px',
      gridTemplateAreas: `'title' 'bitcoin' 'timer'`,
    },
  },
  primaryText: {
    color: colors.white,
    textAlign: 'left',
    marginLeft: '5px',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '3.43rem',
    '@media (max-width: 800px)': {
      flexDirection: 'row',
      gap: '5px',
      fontSize: '1.4rem',
    },
  },
  secondaryText: {
    color: colors.primaryGold,
    display: 'flex',
    textAlign: 'initial',
    marginLeft: '5px',
  },
  timeOutBlock: {
    gridArea: 'timer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    gap: '5px',
    fontWeight: 400,
    '@media (max-width: 800px)': {
      '&>span': {
        fontSize: '0.8rem',
      },
    },
  },
  bitcoinBlockInfo: {
    gridArea: 'bitcoin',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1.9rem',
    flexWrap: 'wrap',
    fontWeight: 400,
  },
  titleBlock: {
    gridArea: 'title',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    fontSize: '3.43rem',
    fontWeight: 700,
    '@media (max-width: 800px)': {
      '&>div': {
        fontSize: '1.4rem',
        '&>div': {
          fontSize: '1.4rem',
        },
      },
    },
  },
  image: {
    margin: '0px 5px',
    '@media (max-width: 800px)': {
      maxWidth: '10px',
      maxHeight: '10px',
    },
  },
  bitcoinImage: {
    margin: '0px 5px',
    '@media (max-width: 800px)': {
      maxWidth: '15px',
      maxHeight: '16px',
    },
  },
  logoImage: {
    maxWidth: '100%',
    '@media (max-width: 800px)': {
      maxWidth: ' 50%',
      margin: '40px',
    },
  },
})
