import { Link } from '@material-ui/core'
import { LIQUID_MINING } from 'assets/constants'
import arrowGold from 'assets/icons/arrowGold.svg'
import bitcoin from 'assets/icons/bitcoin.png'
import liquidLogo from 'assets/icons/liquidGoldLogo.png'
import { LiquidBannerProps } from 'core/interfaces/pages/claim/LiquidBannerProps'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { getTimeToTheDate } from 'utils/dateFormatter'
import { useStyles } from './styles'

export const LiquidBanner = ({ lockupInfo }: LiquidBannerProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const formattedData = getTimeToTheDate(Date.now(), lockupInfo.endPromoTimestamp, t).join(' : ')

  return (
    <Link href={LIQUID_MINING} rel="noreferrer" target="_blank" className={classes.root}>
      <div className={classes.bannerContainer}>
        <div className={classes.logoContainer}>
          <img src={liquidLogo} alt="logo icon" className={classes.logoImage} />
        </div>

        <div className={classes.infoContainer}>
          <div className={classes.titleBlock}>
            <div className={classes.primaryText}>
              {t('liquid_banner_join')}
              <img src={arrowGold} alt="arrow" width="30" className={classes.image} />
            </div>
            <div className={[classes.primaryText, classes.row].join(' ')}>
              <Trans
                i18nKey="liquid_banner_liquid_promo"
                components={[<div key="0" className={classes.primaryText} />]}
              />
            </div>
          </div>

          <div className={classes.timeOutBlock}>
            <span className={classes.primaryText}>{t('liquid_banner_program_ends')}</span>
            <span className={classes.secondaryText}>{formattedData}</span>
          </div>

          <div className={classes.bitcoinBlockInfo}>
            <span className={classes.primaryText}>{t('liquid_banner_receive')}</span>
            <div className={classes.secondaryText}>
              <img src={bitcoin} alt="bitcoin" width="30" height="30" className={classes.bitcoinImage} />
              {t('liquid_banner_bitcoin')}
            </div>
            <span className={classes.primaryText}>{t('liquid_banner_mining_rewards')}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}
