import { Skeleton } from '@material-ui/lab'
import { HOW_TO_CLAIM, LIQUID_MINING } from 'assets/constants'
import triangleAttention from 'assets/icons/triangleAttention.svg'
import { CommonCard } from 'components/commonCard'
import { Trans } from 'react-i18next'
import { useStyles } from './styles'

export const LockTokensInfoSkeleton = (): JSX.Element => {
  const classes = useStyles()

  return (
    <CommonCard>
      <Skeleton className={classes.skeleton} />

      <div className={classes.infoContent}>
        <img src={triangleAttention} alt="triangleAttention" />
        <span className={classes.boldTitle}>
          <Trans
            i18nKey="promo_rewards_claiming"
            components={[
              <a className={classes.boldInfo} href={LIQUID_MINING} target="_blank" rel="noreferrer" key="0" />,
            ]}
          />
        </span>
      </div>

      <div className={classes.сlaimInfoContent}>
        <span className={classes.boldTitle}>
          <Trans
            i18nKey="promo_how_to_claim_video"
            components={[
              <a className={classes.boldInfo} href={HOW_TO_CLAIM} target="_blank" rel="noreferrer" key="0" />,
            ]}
          />
        </span>
      </div>
    </CommonCard>
  )
}
