import { IconButton } from '@material-ui/core'
import { MEDIUM_LINK, TELEGRAM_LINK, TWITTER_LINK } from 'assets/constants'
import mediumIcon from 'assets/icons/mediumIcon.svg'
import telegramIcon from 'assets/icons/telegramIcon.svg'
import twitterIcon from 'assets/icons/twitterIcon.svg'
import React from 'react'
import { useStyles } from './styles'

const social_links = [
  {
    image: mediumIcon,
    name: 'medium',
    path: MEDIUM_LINK,
  },
  {
    image: telegramIcon,
    name: 'telegram',
    path: TELEGRAM_LINK,
  },
  {
    image: twitterIcon,
    name: 'twitter',
    path: TWITTER_LINK,
  },
]

export const SocialIcons = (): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {social_links.map((link, index) => (
        <IconButton key={index} href={link.path} target="_blank" rel="noreferrer">
          <img className={classes.image} src={link.image} alt={`${link.name} link`} />
        </IconButton>
      ))}
    </div>
  )
}
