import { makeStyles } from '@material-ui/core'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '&>img': {
      '@media (max-width: 800px)': {
        maxWidth: '15px',
      },
    },
  },
  info: {
    fontSize: '1.8rem',
    color: colors.primaryInfoText,

    '@media (max-width: 800px)': {
      fontSize: '1.4rem',
    },
  },
})
