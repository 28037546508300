import { DAISY_PRECISION, UPLIFT_BRIDGE } from 'assets/constants'
import arrowGreen from 'assets/icons/arrowGreen.svg'
import { ConfirmationButton } from 'components/buttons/confirmationButton'
import { GlobalBigButton } from 'components/buttons/globalBigButton'
import { CommonInfoDescription } from 'components/commonInfoDescription'
import { ExchangeInput } from 'components/exchange/input'
import { TutorialBlock } from 'components/tutorialBlock'
import { tronSets } from 'contracts/sets/sets'
import { useExchange } from 'hooks/exchange/useExchange'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import { useAllowance, useApprove, useBalance } from 'hooks/trc20/useTRC10'
import { useTRXBalance } from 'hooks/trc20/useTRX'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useRefresh } from 'services/RefreshContextProvider'
import { useTransactionService } from 'services/TransactionService'
import { fromBNish, fromDecimals } from 'utils/tsUtils'
import { approveStateErrorMessage, approveValidateState } from './approveUtils'
import { useStyles } from './styles'
import { swapStateErrorMessage, swapValidateState } from './swapUtils'

export const Exchange = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { chainId, account } = useStrictTron()

  const { slowRefresh } = useRefresh()
  const { DAISY, exchange, LIFT } = tronSets[chainId]
  const { filterTransactions } = useTransactionService()
  const pendingTransactions = filterTransactions({ statuses: ['unknown'], actions: ['approveExchange', 'exchange'] })

  const [allowance, refreshAllowance] = useAllowance(exchange, DAISY)
  const [balanceDaisy, refreshBalanceDaisy] = useBalance(DAISY, account)
  const [balanceLift, refreshBalanceLift] = useBalance(LIFT, account)
  const [balanceTRX, refreshBalanceTRX] = useTRXBalance()

  const [inputValue, setInputValue] = useState<string>('')
  // TODO: change isLoading to globale transaction confirmation handling
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const inputValueBN = fromDecimals(inputValue, DAISY_PRECISION)

  useEffect(() => {
    refreshBalanceDaisy()
    refreshBalanceLift()
    refreshAllowance()
    refreshBalanceTRX()
  }, [slowRefresh])

  const approveRequest = useApprove(DAISY)
  const onApprove = async () => {
    setIsLoading(true)
    await approveRequest(exchange, 'approveExchange')
    setIsLoading(false)
    setInputValue('')
  }

  const exchangeRequest = useExchange(exchange)
  const onSwap = async () => {
    setIsLoading(true)
    await exchangeRequest(inputValueBN)
    setIsLoading(false)
    setInputValue('')
  }

  const errorStatus = swapValidateState(balanceTRX ?? fromBNish('0'), balanceDaisy ?? fromBNish('0'), inputValueBN)
  const errorMessage = swapStateErrorMessage(errorStatus, t, inputValueBN)
  const approveErrorState = approveValidateState(
    balanceTRX ?? fromBNish('0'),
    balanceDaisy ?? fromBNish('0'),
    inputValueBN,
  )
  const approveErrorMessage = approveStateErrorMessage(approveErrorState, inputValueBN, t)

  const buttonState = () => {
    switch (true) {
      case !!pendingTransactions.length || isLoading:
        return <ConfirmationButton />
      case allowance?.lt(inputValueBN):
        return (
          <GlobalBigButton
            errorMessage={approveErrorMessage}
            title={t('common_approve_button')}
            onClick={onApprove}
            isDisabled={!!errorMessage}
          />
        )
      default:
        return (
          <GlobalBigButton
            onClick={onSwap}
            title={t('exchange_page_swap_button')}
            errorMessage={errorMessage}
            isDisabled={!!errorMessage}
          />
        )
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.card}>
          <div className={classes.titleTextContainer}>
            <span className={classes.primaryTitle}>
              <Trans
                i18nKey="exchange_page_exchange_daisy"
                components={[<span className={classes.secondaryTitle} key="0" />]}
              />
            </span>
          </div>
          <ExchangeInput
            value={inputValue}
            onValueChange={setInputValue}
            decimals={DAISY_PRECISION}
            maxValue={balanceDaisy ?? fromBNish('0')}
            disabled={isLoading || !!pendingTransactions.length}
          />
        </div>

        <CommonInfoDescription
          title={t('exchange_page_description_title')}
          description={t('exchange_page_description')}
        />

        <div className={classes.buttonContainer}>
          <div className={classes.linkTextContainer}>
            {buttonState()}
            {balanceLift && !balanceLift.isZero() && pendingTransactions.length === 0 && !isLoading && (
              <div className={classes.titleMargin}>
                <a href={UPLIFT_BRIDGE} className={classes.link} target="_blank" rel="noreferrer">
                  {t('exchange_page_redirect_to_bridge')}
                </a>
                <img src={arrowGreen} alt="arrowGreen" />
              </div>
            )}
          </div>
        </div>
        <TutorialBlock />
      </div>
    </div>
  )
}
