/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ADDRESS_PREFIX } from 'assets/constants'
import { AbiCoder } from 'ethers/lib/utils'

//types:Parameter type list, if the function has multiple return values, the order of the types in the list should conform to the defined order
//output: Data before decoding
//ignoreMethodHash：Decode the function return value, fill falseMethodHash with false, if decode the data field in the gettransactionbyid result, fill ignoreMethodHash with true
export function decodeParams(types: any, output: any, ignoreMethodHash: any): any {
  if (!output || typeof output === 'boolean') {
    ignoreMethodHash = output
    output = types
  }

  if (ignoreMethodHash && output.replace(/^0x/, '').length % 64 === 8) {
    output = '0x' + output.replace(/^0x/, '').substring(8)
  }

  const abiCoder = new AbiCoder()

  if (output.replace(/^0x/, '').length % 64) {
    throw new Error('The encoded string is not valid. Its length must be a multiple of 64.')
  }
  return abiCoder.decode(types, output).reduce((obj: any, arg: any, index: number) => {
    if (types[index] == 'address') {
      arg = ADDRESS_PREFIX + arg.substr(2).toLowerCase()
    }
    obj.push(arg)
    return obj
  }, [])
}
