import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    '@media (max-width: 800px)': {
      gridTemplateColumns: '1fr',
    },
  },
  primaryText: {
    color: colors.secondaryLightGrey,
    fontSize: '1.3rem',
    marginBottom: '20px',
  },
  secondaryText: {
    color: colors.primaryGreen,
    marginBottom: '20px',
  },
})
