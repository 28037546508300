import { Skeleton } from '@material-ui/lab'
import { GlobalBigButtonSkeleton } from 'components/buttons/globalBigButtonSkeleton'
import { CommonInfoDescription } from 'components/commonInfoDescription'
import { useTranslation } from 'react-i18next'
import { PoolsCardSkeleton } from '../poolsCardSkeleton'
import { useStyles } from './styles'

export const ClaimSkeletonPage = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <Skeleton className={classes.skeleton} />
        <CommonInfoDescription title={t('claim_page_vesting')} description={t('claim_page_vesting_info')} />
        <PoolsCardSkeleton />
        <CommonInfoDescription title={t('claim_page_vesting')} description={t('claim_page_pools_vesting_info')} />
        <GlobalBigButtonSkeleton />
      </div>
    </div>
  )
}
