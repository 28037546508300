import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  content: {
    width: '100%',
    color: colors.primaryGreen,
    flexGrow: 1,
  },
  bannersContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    height: '70vh',
  },
  contentWrap: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    padding: '0px 134px 100px 120px',
    boxSizing: 'border-box',
    '@media (max-width: 1200px)': {
      padding: '0px 114px 80px 100px',
    },
    '@media (max-width: 1000px)': {
      padding: '0px 54px 20px 40px',
    },
    '@media (max-width: 600px)': {
      padding: '0px 20px 10px',
    },
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 1000px)': {
      padding: '40px 50px',
    },
    '@media (max-width: 600px)': {
      padding: '30px 10px',
    },
    '@media (max-width: 400px)': {
      padding: '20px 0px',
    },
  },
})
