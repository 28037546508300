import { Tooltip } from '@material-ui/core'
import { DAISY_PRECISION } from 'assets/constants'
import questionMark from 'assets/icons/questionMark.svg'
import { ProgressBar } from 'components/progressBar'
import { ClaimPoolCardProps } from 'core/interfaces/components/claimPoolCard/ClaimPoolCardProps'
import { useStrictLockupInfo } from 'hooks/strict/lockupInfo/useStrictlockupInfo'
import moment from 'moment'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { formattedDecimals } from 'utils/tsUtils'
import { useStyles } from './styles'

export const ClaimPoolCard = ({
  title,
  distribution,
  totalAmount,
  availableDaisy,
  locked,
}: ClaimPoolCardProps): JSX.Element => {
  const { t } = useTranslation()
  const lockupInfo = useStrictLockupInfo()
  const classes = useStyles()
  const formattedAvailableAmount = formattedDecimals(availableDaisy, DAISY_PRECISION)
  const formattedTotalAmount = formattedDecimals(totalAmount, DAISY_PRECISION)
  const tooltipTitle = t('liquid_banner_info_about_lock_tokens', {
    time: moment(lockupInfo.unlockTokenTimestamp).format('lll Z [GMT]'),
  })
  const isLocked = !locked.isZero()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <span className={classes.title}>{title}</span>

        <div className={classes.column}>
          <span className={classes.primaryInfoText}>
            <Trans
              i18nKey="claim_page_total_amount_daisy"
              components={[<span className={classes.secondaryInfoText} key="0" />]}
            />
          </span>
          <div className={classes.row}>
            <span className={isLocked ? classes.secondaryAmountText : classes.primaryAmountText}>
              {formattedTotalAmount}
            </span>
            {isLocked && (
              <Tooltip title={tooltipTitle} placement="top">
                <img src={questionMark} alt="questionMarkIcon" />
              </Tooltip>
            )}
          </div>
        </div>

        <div className={classes.separator} />

        <div className={classes.row}>
          <span className={classes.primaryInfoText}>
            <Trans
              i18nKey="claim_page_available_daisy"
              components={[<span className={classes.secondaryInfoText} key="0" />]}
            />
          </span>
          <span className={classes.primaryAmountText}>{formattedAvailableAmount}</span>
        </div>

        <ProgressBar
          claimedAmount={distribution}
          availableToClaimAmount={availableDaisy}
          totalAmount={totalAmount}
          hasLegend={false}
        />
      </div>
    </div>
  )
}
