import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
})
