import { ChainEnum } from 'core/enums/services/ChainEnum'
import { TronConfig } from 'core/interfaces/services/Tron'

export const tronSets: { [key in ChainEnum]: TronConfig } = {
  [ChainEnum.MAINNET]: {
    DAISY: 'TPrDk8WfuMheRFiC3Q5FsmCq74GuQXGcdL',
    USDT: 'TDZHjjDTT2xak1XjSzp1AuU1JZv7zHiNLD',
    LIFT: 'TGo8MJaSxvVVicZ1ixC7n3ibJMt9NJvxuU',
    transactionLink: 'https://tronscan.org/#/transaction/',
    presale: 'TBRs8xwajQVbDLT11SKZwFbsnJXRCKMi9x',
    presaleReferral: 'TQyrPH3AAod7PEhEaMw2gTGyzTSc6A3M3c',
    unlock: 'TKPzmgdtKV1gQUcTjpXqQNhmFxWzDJp7Ln',
    exchange: 'TFKjE6JLvfkkdFgU8qpUFFFKnjQhxeKokL',
    withdrawController: 'TTuLRNVASpLXkov5daAd3cc9vCeeykj75C',
    additionalPools: [
      'TAjwHgP5xMQAHjYwJqNFGtZieSiFz8Y2Fp', // spillover maker pool 1
      'TVt59cuN3npv4d9SxmiRpWDbttxav8AzQo', // spillover maker pool 2
      'TDRW7dk5ctT6praoWVmMXE6QrxWvAJEt3w', // pacesetters gold pool
      'TPWLJ8NdNWuu3iVXp76Dp9SY1eBzn1Aiit', // leadership pool
    ],
    otherAdditionalPools: [
      'TTBTaS1bwjenb8xkYwff4qLGTtwh7uVMGq', //moment
      'TGhwPewWZL6s8jdtkHk1wD8VGw1v3pFMfi', //tiers
    ],
  },
  [ChainEnum.SHASTA]: {
    DAISY: 'TWNV48yV53sctvaEowDyTqe69R9LebsKsf',
    USDT: 'TDZHjjDTT2xak1XjSzp1AuU1JZv7zHiNLD',
    LIFT: 'TCQ8wUSui4nRyiGemDCSabVcJtARwUMHeY',
    transactionLink: 'https://shasta.tronscan.org/#/transaction/',
    presale: 'TDTG7GiDki6HTZwaDScUEgFUgo1djf7MeA',
    presaleReferral: 'TFJfAH4HoZsJi5rUyLyCmyvnzzLWH9a5Rn',
    unlock: 'TEjX18JPAchRChsCqSJwa4yRgESEAqzMUq', //main pool
    exchange: 'TV5gLw1so32XZyCguBjm2AQutTxZiVRJg5',
    withdrawController: 'TAKTd7YKRzZ8tDfZcCC9T42S9s777ypRk7',
    additionalPools: [
      'TChawoY8qaBXPybDbaCGjJZeLUp4Spxpuv', // spillover maker pool 1
      'TRv9gWrhtY9nNtQeHLH87VBRsxzffkYqjm', // spillover maker pool 2
      'TB31qgoEeb6PVFAjMdTmMCRzit28BwEofF', // pacesetters gold pool
      'TS531NLTJceoEaraCighJmUYWNoqK2xSfy', // leadership pool
    ],
    otherAdditionalPools: [
      'TKNS1ZCxF3AFdd5vxHBoCx4KtNLSmDGYfg', //moment
      'TM15dB1HA7qDa7G4xdVsBkgCguYiq1a9gZ', //tiers
    ],
  },
}
