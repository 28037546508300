/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FEE_LIMIT } from 'assets/constants'
import { TokenExchangeABI } from 'contracts/abi/TokenExchange'
import { RequestDelayUtils } from 'utils/tron/RequestDelayUtils'
import { BN } from 'utils/tsUtils'

async function exchange(tronWeb: any, contractAddress: string, amount: BN): Promise<string> {
  await RequestDelayUtils.addDelay()
  const contract = await tronWeb.contract(TokenExchangeABI, contractAddress)
  return await contract.exchange(amount).send({ feeLimit: FEE_LIMIT })
}

export const TokenExchangeAPI = {
  exchange: exchange,
}
