import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  primaryTitle: {
    color: colors.primaryGreen,
    fontSize: '1.715rem',
    fontWeight: 400,
    '@media (max-width: 300px)': {
      fontSize: '1rem',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',

    '&>img': {
      '@media (max-width: 800px)': {
        maxWidth: '15px',
      },
    },
  },
  title: {
    color: colors.secondaryLightGrey,
    fontSize: '1.715rem',
    '@media (max-width: 300px)': {
      fontSize: '1rem',
    },
  },
  mainContainer: {
    marginTop: '10px',
  },
})
