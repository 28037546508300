import { makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from 'utils/mui'
import { ProgressBarStylesProps } from 'core/interfaces/components/progressBar/styles/ProgressBarStyleProps'

const getProgressOffset = (progress: number) => {
  const padding = 2
  const relateOffset = 100 - progress
  const absoluteOffset = padding + 2 * padding * (relateOffset / 100)
  return `calc(-${relateOffset}% - ${absoluteOffset}px)`
}

export const useStyles = makeStyles<Theme, ProgressBarStylesProps>({
  root: {
    width: '100%',
    marginBottom: (props) => `${props.secondaryWidth === 0 ? '20' : '0'}px`,
  },
  zeroProgressLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ' flex-start',
    position: 'relative',
    zIndex: 10,
    marginTop: '-15px',
    marginLeft: '10px',
  },
  primaryProgressLabel: {
    width: (props) => `${Math.max(Math.min(props.primaryGap, 100), 0)}%`,
    textAlign: 'right',
    position: 'relative',
    zIndex: 10,
    marginTop: '-15px',
  },
  secondaryProgressLabel: {
    width: (props) => `${Math.max(Math.min(props.secondaryGap, 100), 0)}%`,
    textAlign: 'right',
    position: 'relative',
    zIndex: 10,
  },
  primaryLabel: {
    color: colors.black,
    fontSize: '0.715rem',
    paddingRight: '5px',
    fontWeight: 700,
    '@media (max-width: 550px)': {
      paddingRight: '0px',
    },
  },
  primarySubTitleLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'relative',
    paddingRight: '15px',
  },
  secondarySubTitleLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  primarySubTitle: {
    color: colors.primaryGreen,
    fontSize: '0.86rem',
    fontWeight: 700,
    margin: '2px 0px',
  },
  secondarySubTitle: {
    color: colors.primaryOrange,
    fontSize: '0.86rem',
    fontWeight: 700,
    margin: '2px 0px',
  },
  tertiarySubTitle: {
    color: colors.white,
    fontSize: '0.86rem',
    paddingRight: '10px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 280px)': {
      flexDirection: 'column',
    },
  },
  progressBar: {
    '& .MuiLinearProgress-bar1Buffer': {
      width: 'calc(100% + 4px)',
      left: (props) => getProgressOffset(props.primaryWidth),
      transform: 'skewX(-30deg) !important',
    },
    '& .MuiLinearProgress-bar2Buffer': {
      width: 'calc(100% + 4px)',
      left: (props) => getProgressOffset(props.secondaryWidth),
      transform: 'skewX(-30deg) !important',
    },
  },
})
