import { TimerProps } from 'core/interfaces/utils/timer/TimerProps'
import moment from 'moment'
import { TFunction } from 'react-i18next'

export const DAY_IN_SECOND = 60 * 60 * 24

export function secondsToMillis(date: number): number {
  return date * 1000.0
}

export function timeOut(now: number, startDate: number, t: TFunction<'translation'>): TimerProps {
  const startMoment = moment(now)
  const endMoment = moment(secondsToMillis(startDate))

  let timeDifferenceInSeconds = endMoment.diff(startMoment, 'seconds')
  const years = Math.floor(timeDifferenceInSeconds / (3600 * 24 * 365))
  timeDifferenceInSeconds -= years * 3600 * 24 * 365
  const days = Math.floor(timeDifferenceInSeconds / (3600 * 24))
  timeDifferenceInSeconds -= days * 3600 * 24
  const hours = Math.floor(timeDifferenceInSeconds / 3600)
  timeDifferenceInSeconds -= hours * 3600
  const minutes = Math.floor(timeDifferenceInSeconds / 60)
  timeDifferenceInSeconds -= minutes * 60

  if (timeDifferenceInSeconds < 0) {
    return {
      days: 0,
      daysName: t('plurals_day_template', { count: 0 }),
      hours: 0,
      hoursName: t('plurals_hour_template', { count: 0 }),
      minutes: 0,
      minutesName: t('plurals_minute_template', { count: 0 }),
      seconds: 0,
      secondsName: t('plurals_second_template', { count: 0 }),
    }
  }
  return {
    days: days,
    daysName: t('plurals_day_template', { count: days }),
    hours: hours,
    hoursName: t('plurals_hour_template', { count: hours }),
    minutes: minutes,
    minutesName: t('plurals_minute_template', { count: minutes }),
    seconds: timeDifferenceInSeconds,
    secondsName: t('plurals_second_template', { count: timeDifferenceInSeconds }),
  }
}

// TODO: refactor this shit!!!
export function getTimeToTheDate(nowInMillis: number, endDateInSecond: number, t: TFunction<'translation'>): string[] {
  const startMoment = moment(nowInMillis)
  const endMoment = moment(endDateInSecond)

  let timeDifferenceInSeconds = endMoment.diff(startMoment, 'seconds')
  if (timeDifferenceInSeconds < 0) {
    return []
  }

  const days = Math.floor(timeDifferenceInSeconds / (3600 * 24))
  timeDifferenceInSeconds -= days * 3600 * 24
  const hours = Math.floor(timeDifferenceInSeconds / 3600)
  timeDifferenceInSeconds -= hours * 3600
  const minutes = Math.floor(timeDifferenceInSeconds / 60)
  timeDifferenceInSeconds -= minutes * 60

  if (days > 0) {
    return [
      `${t('plurals_day_template', { count: days })}`,
      `${t('plurals_hour_template', { count: hours })}`,
      `${t('plurals_minute_template', { count: minutes })}`,
      `${t('plurals_second_template', { count: timeDifferenceInSeconds })}`,
    ]
  }
  if (hours > 0) {
    return [
      `${t('plurals_hour_template', { count: hours })}`,
      `${t('plurals_minute_template', { count: minutes })}`,
      `${t('plurals_second_template', { count: timeDifferenceInSeconds })}`,
    ]
  }
  if (minutes > 0) {
    return [
      `${t('plurals_minute_template', { count: minutes })}`,
      `${t('plurals_second_template', { count: timeDifferenceInSeconds })}`,
    ]
  }
  if (timeDifferenceInSeconds > 0) {
    return [`${t('plurals_second_template', { count: timeDifferenceInSeconds })}`]
  }
  return []
}

export function formattedTimeDescription(timeInfo: string[], minTimeDescriptionLength: number): string {
  //TODO: create enum for minTimeDescriptionLength
  if (timeInfo.length === 0) {
    return ''
  }
  if (timeInfo.length > 2) {
    return timeInfo.slice(0, -(timeInfo.length - minTimeDescriptionLength)).join(' ')
  }
  return timeInfo.join(' ')
}
