import { TRC10API } from 'api/tron/TRC10API'
import { FEE_LIMIT, MAX_INT_VALUE, TRON_WEB_ENVIRONMENTS_NOT_FOUND } from 'assets/constants'
import { LocalStorageKeysEnum } from 'core/enums/services/LocalStorageKeysEnum'
import { TransactionAction } from 'core/interfaces/services/Transaction'
import { Cached } from 'core/interfaces/utils/cache/cache'
import { BigNumber } from 'ethers'
import useLocalStorage from 'hooks/useLocalStorage'
import { useCallback, useEffect, useState } from 'react'
import { useTronWeb } from 'services/AccountService'
import { useTransactionService } from 'services/TransactionService'
import { getLocalStorageName } from 'utils/transformLocalStorage'
import { BN, fromBNish, VoidPromiseCallback } from 'utils/tsUtils'

export function useBalance(contractAddress: string, account: string): [BigNumber | undefined, VoidPromiseCallback] {
  const { tronWeb, chain } = useTronWeb()
  const lockupInfoCacheKey = getLocalStorageName(`${LocalStorageKeysEnum.BALANCE}_${contractAddress}_${account}`)
  const [lockupInfoCache, setLockupInfoCache] = useLocalStorage<Cached<BigNumber | undefined>>(
    lockupInfoCacheKey,
    undefined,
  )
  const [balance, setBalance] = useState<BigNumber | undefined>(fromBNish(lockupInfoCache ?? '0'))

  async function request() {
    if (!tronWeb || !chain) {
      throw TRON_WEB_ENVIRONMENTS_NOT_FOUND
    }

    try {
      const balance = await TRC10API.balanceOf(tronWeb, contractAddress, account)
      setLockupInfoCache(balance.toString())
      setBalance(balance)
    } catch (error) {
      console.error('useTRC10', error)
    }
  }

  useEffect(() => void (contractAddress && request()), [tronWeb, account, chain, contractAddress])
  const refreshBalance = useCallback<VoidPromiseCallback>(request, [tronWeb, account, chain, contractAddress])
  return [balance, refreshBalance]
}

type AllowanceRefresh = VoidPromiseCallback
const allowanceCache: Record<string, BN | undefined> = {}

export function useAllowance(contract: string, tokenContractAddress: string): [BN | undefined, AllowanceRefresh] {
  const { tronWeb, account, chain } = useTronWeb()
  const key = `${account}_${chain}_${contract}_${tokenContractAddress}`
  const [allowance, setAllowance] = useState<BN | undefined>(allowanceCache[key])

  async function request(): Promise<void> {
    if (!tronWeb || !account) {
      throw TRON_WEB_ENVIRONMENTS_NOT_FOUND
    }

    try {
      const allowance = await TRC10API.allowance(tronWeb, tokenContractAddress, account, contract)
      allowanceCache[key] = allowance
      setAllowance(allowance)
    } catch (error) {
      console.error('useAllowance', error)
    }
  }

  useEffect(() => void request(), [tronWeb, account, chain, contract, tokenContractAddress])
  const refreshAllowance = useCallback<AllowanceRefresh>(request, [
    tronWeb,
    account,
    chain,
    contract,
    tokenContractAddress,
  ])
  return [allowance, refreshAllowance]
}

type ApproveProps = (spender: string, transactionAction: TransactionAction) => Promise<void>

export function useApprove(contractAddress: string): ApproveProps {
  const { tronWeb } = useTronWeb()
  const { registerTransaction, handleTransactionError } = useTransactionService()

  const request: ApproveProps = async (spender, transactionAction): Promise<void> => {
    if (!tronWeb) {
      throw TRON_WEB_ENVIRONMENTS_NOT_FOUND
    }

    try {
      const message = { feeLimit: FEE_LIMIT }
      const hash = await TRC10API.approve(tronWeb, contractAddress, spender, MAX_INT_VALUE, message)
      registerTransaction(hash, { action: transactionAction })
    } catch (error: unknown) {
      console.error('useApprove', error)
      handleTransactionError(error)
    }
  }

  return useCallback<ApproveProps>(request, [tronWeb, contractAddress, registerTransaction])
}

const totalSupplyCache: Record<string, BigNumber | undefined> = {}

export function useTotalSupply(contractAddress: string): [BigNumber | undefined, VoidPromiseCallback] {
  const { tronWeb } = useTronWeb()

  const key = `${contractAddress}`
  const [totalSupply, setTotalSupply] = useState<BigNumber | undefined>(totalSupplyCache[key])

  async function request() {
    if (!tronWeb) {
      throw TRON_WEB_ENVIRONMENTS_NOT_FOUND
    }

    try {
      const _totalSupply = await TRC10API.totalSupply(tronWeb, contractAddress)
      totalSupplyCache[key] = _totalSupply
      setTotalSupply(_totalSupply)
    } catch (error) {
      console.error('useTotalSupply', error)
    }
  }

  const refreshTotalSupply = useCallback<VoidPromiseCallback>(request, [tronWeb, contractAddress])
  return [totalSupply, refreshTotalSupply]
}
