import { Trans, useTranslation } from 'react-i18next'
import { LockTokensInfo } from '../components/lockTokensInfo'
import { PromoInfo } from '../components/promoInfo'
import { LockupInfoStatsWrapper } from '../wrappers/lockupStatsInfo'
import { LockupInfoWrapper } from '../wrappers/lockupInfo'
import { useStyles } from './styles'

export const LiquidPromo = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <LockupInfoStatsWrapper>
          <LockupInfoWrapper>
            <PromoInfo />
            <LockTokensInfo />
          </LockupInfoWrapper>
        </LockupInfoStatsWrapper>

        <div className={classes.titleContainer}>
          <span className={classes.title}>{t('promo_liquid_promo_details')}</span>
          <span className={classes.primaryInfoText}>
            <Trans
              i18nKey="promo_liquid_promo_poll_description_one"
              components={[<span className={classes.secondaryInfoText} key="0" />]}
            />
          </span>
          <span className={classes.primaryInfoText}>{t('promo_liquid_promo_poll_description_two')}</span>
        </div>
      </div>
    </div>
  )
}
