import { LockupInfo, PoolInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { secondsToMillis } from 'utils/dateFormatter'
import { fromBNish } from 'utils/tsUtils'

type DecodedPoolsInfo = [
  [DecodedLockupInfo],
  [DecodedAdditionalPoolInfo, DecodedAdditionalPoolInfo, DecodedAdditionalPoolInfo, DecodedAdditionalPoolInfo],
]
export type DecodedAdditionalPoolInfo = [string, string, string, string, string, number[], number[], number[]]
type DecodedLockupInfo = [
  string,
  string,
  string,
  string,
  string,
  string,
  number[],
  number[],
  number[],
  number,
  number,
  number,
  string,
  [number, string][],
]

export function parsePoolsInfo(
  decodedInfo: DecodedPoolsInfo,
  presaleAddress: string,
  poolAddresses: string[],
): LockupInfo {
  const generalInfo = parseLockupInfo(decodedInfo[0][0], presaleAddress)
  const poolInfos = decodedInfo[1].map((decoded, index) => parseAdditionalPool(decoded, poolAddresses[index]))
  const additionalPools = poolInfos.slice(0, 4) as [PoolInfo, PoolInfo, PoolInfo, PoolInfo]
  const otherAdditionalPools = poolInfos.slice(4, 6) as [PoolInfo, PoolInfo]

  const availableToClaimAmount = [...additionalPools, ...otherAdditionalPools]
    .reduce((acc, pool) => acc.add(pool.availableToClaim), fromBNish(0))
    .add(generalInfo.mainPool.availableToClaim)

  return {
    ...generalInfo,
    additionalPools,
    otherAdditionalPools,
    availableToClaimAmount,
  }
}

export function parseAdditionalPool(decoded: DecodedAdditionalPoolInfo, poolAddress: string): PoolInfo {
  const total = fromBNish(decoded[0])
  const availableToClaim = fromBNish(decoded[2])
  return {
    poolAddress,
    total,
    claimed: total.sub(decoded[1]),
    availableToClaim,
    merkleInfoLink: decoded[3],
    locked: fromBNish(0),
  }
}

export function parseLockupInfo(
  decodedLockupInfo: DecodedLockupInfo,
  presaleAddress: string,
): Omit<LockupInfo, 'additionalPools' | 'otherAdditionalPools' | 'availableToClaimAmount'> {
  const confirmedFromPresale = fromBNish(decodedLockupInfo[0])
  const confirmedFromReferralPresale = fromBNish(decodedLockupInfo[1])
  const unconfirmedFromPresale = fromBNish(decodedLockupInfo[2])
  const unconfirmedFromReferralPresale = fromBNish(decodedLockupInfo[3])

  const notConfirmedTokens = unconfirmedFromPresale.gt(confirmedFromPresale)
    ? unconfirmedFromPresale.sub(confirmedFromPresale)
    : fromBNish(0)
  const notConfirmedReferralTokens = unconfirmedFromReferralPresale.gt(confirmedFromReferralPresale)
    ? unconfirmedFromReferralPresale.sub(confirmedFromReferralPresale)
    : fromBNish(0)

  return {
    notConfirmedTokens,
    notConfirmedReferralTokens,
    startPromoTimestamp: secondsToMillis(+decodedLockupInfo[9]),
    endPromoTimestamp: secondsToMillis(+decodedLockupInfo[10]),
    unlockTokenTimestamp: secondsToMillis(+decodedLockupInfo[11]),
    promoPoolShares: fromBNish(decodedLockupInfo[12]),
    mainPool: {
      total: confirmedFromPresale.add(confirmedFromReferralPresale),
      claimed: fromBNish(decodedLockupInfo[4]),
      availableToClaim: fromBNish(decodedLockupInfo[5]),
      merkleInfoLink: '',
      locked: fromBNish(0),
      poolAddress: presaleAddress,
    },
  }
}

const defaultEmptyPool: PoolInfo = {
  total: fromBNish('0'),
  claimed: fromBNish('0'),
  availableToClaim: fromBNish('0'),
  merkleInfoLink: '',
  poolAddress: '',
  locked: fromBNish(0),
}

export function parseOldLockupInfo(
  decodedLockupInfo: DecodedLockupInfo,
  presaleAddress: string,
  additionalPoolAddresses: [string, string, string, string],
  otherAdditionalPoolAddresses: [string, string],
): LockupInfo {
  const generalInfo = parseLockupInfo(decodedLockupInfo, presaleAddress)
  return {
    ...generalInfo,
    availableToClaimAmount: generalInfo.mainPool.availableToClaim,
    additionalPools: [
      { ...defaultEmptyPool, poolAddress: additionalPoolAddresses[0] },
      { ...defaultEmptyPool, poolAddress: additionalPoolAddresses[1] },
      { ...defaultEmptyPool, poolAddress: additionalPoolAddresses[2] },
      { ...defaultEmptyPool, poolAddress: additionalPoolAddresses[3] },
    ],
    otherAdditionalPools: [
      { ...defaultEmptyPool, poolAddress: otherAdditionalPoolAddresses[0] },
      { ...defaultEmptyPool, poolAddress: otherAdditionalPoolAddresses[1] },
    ],
  }
}
