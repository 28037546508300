import { BigNumber } from 'ethers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toDecimals } from 'utils/tsUtils'
import { useStyles } from './styles'

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export const ExchangeInput = React.memo(function InnerInput({
  value,
  placeholder,
  onValueChange,
  maxValue,
  decimals,
  ...rest
}: {
  value: string
  onValueChange: (value: string) => void
  maxValue: BigNumber
  decimals: number
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  const { t } = useTranslation()
  const classes = useStyles()
  const excludeValues = (value: string): string => {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }
  const enforcer = (nextUserInput: string): void => {
    const lastIndex = nextUserInput.lastIndexOf('.')
    if (lastIndex !== -1) {
      nextUserInput = nextUserInput.slice(0, lastIndex + 1 + decimals)
    }
    if (nextUserInput === '' || inputRegex.test(excludeValues(nextUserInput))) {
      onValueChange(nextUserInput)
    }
    if (nextUserInput === '.') {
      onValueChange('0.')
    }
    if (
      inputRegex.test(excludeValues(nextUserInput)) &&
      nextUserInput.length > 1 &&
      nextUserInput[0] === '0' &&
      nextUserInput[1] !== '.'
    ) {
      const newInput = nextUserInput[0] + '.' + nextUserInput.slice(1)
      if (inputRegex.test(excludeValues(newInput))) {
        onValueChange(newInput)
      }
    }
  }

  const handlePercent = (): void => onValueChange(toDecimals(maxValue, decimals))

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer}>
        <input
          className={classes.input}
          value={value}
          onChange={(event: { target: HTMLInputElement }) => {
            // replace commas with periods, because uniswap exclusively uses period as the decimal separator
            enforcer(event.target.value.replace(/,/g, '.'))
          }}
          // universal input options
          inputMode="decimal"
          title="Token Amount"
          autoComplete="off"
          autoCorrect="off"
          // text-specific options
          type="text"
          pattern="^[0-9]*[.,]?[0-9]*$"
          placeholder={placeholder || '0.0'}
          minLength={1}
          maxLength={79}
          spellCheck="false"
          {...rest}
        />

        <span
          className={classes.maxContainer}
          data-disabled={!!rest.disabled}
          onClick={() => !rest.disabled && handlePercent()}
        >
          <span>{t('common_max')}</span>
        </span>
      </div>
    </div>
  )
})
