import { colors } from 'utils/mui'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 10,
    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
  },
  link: {
    cursor: 'pointer',
    color: colors.primaryDarkGrey,
    textDecoration: 'underline',
    marginTop: '12px',
    '@media (max-width: 1000px)': {
      marginTop: '4px',
    },
  },
  primaryTitle: {
    marginRight: 'auto',
    color: colors.primaryDarkGrey,
    '@media (max-width: 1000px)': {
      display: 'none',
    },
  },
  secondaryTitle: {
    display: 'none',
    '@media (max-width: 1000px)': {
      display: 'block',
      color: colors.primaryDarkGrey,
      marginTop: '5px',
    },
  },
  marginContainer: {
    marginRight: '30px',
    '@media (max-width: 1000px)': {
      marginRight: '0px',
      width: '60px',
      height: '60px',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '10px',
    },
  },
  column: {
    height: '73px',
    display: 'flex',
    flex: '1 1 0',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  maxWidth: {
    flex: '1 1 0',
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      alignItems: 'flex-end',
    },
    '&:last-child': {
      justifyContent: 'flex-end',
    },
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})
