import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { AttentionBlock } from 'components/modals/attentionBlock'
import React, { ReactNode } from 'react'
import { useStyles } from './styles'

export const Layout = ({ children }: { children: ReactNode }): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.contentWrap}>
      <AttentionBlock />
      <Header />
      <div className={classes.content}>
        <div className={classes.contentContainer}>{children}</div>
      </div>
      <Footer />
    </div>
  )
}
