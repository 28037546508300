import { PrivatePresaleLockupAPI } from 'api/tron/PrivatePresaleLockupAPI'
import { tronSets } from 'contracts/sets/sets'
import { LocalStorageKeysEnum } from 'core/enums/services/LocalStorageKeysEnum'
import { LockupStatsInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { Destructor, HookCommonState } from 'core/interfaces/hooks/HookCommonState'
import { Cached } from 'core/interfaces/utils/cache/cache'
import { useCancelableFactory } from 'hooks/common/commonState/useCancelableFactory'
import { useRequestMemo } from 'hooks/common/commonState/useRequestMemo'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import useLocalStorage from 'hooks/useLocalStorage'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { secondsToMillis } from 'utils/dateFormatter'
import { isHandleableTransactionError } from 'utils/transaction/transactionUtils'
import { getLocalStorageName } from 'utils/transformLocalStorage'
import { fromBNish } from 'utils/tsUtils'

const MAX_STATIC = 30
const START_PROMO_TIMESTAMP = secondsToMillis(1648544400)

export function useLockupStatsInfo(): HookCommonState<LockupStatsInfo | undefined> {
  const { tronWeb: library, account, chainId } = useStrictTron()
  const lockupInfoCacheKey = getLocalStorageName(LocalStorageKeysEnum.LOOKUP_INFO_CACHE_LIQUID)
  const [lockupInfoCache, setLockupInfoCache] = useLocalStorage<Cached<LockupStatsInfo | undefined>>(
    lockupInfoCacheKey,
    undefined,
  )
  const [lockupInfo, setLockupInfo] = useState<LockupStatsInfo | undefined>(() => unwrapLockupInfo(lockupInfoCache))
  const [lastDay, setLastDay] = useState<number>(0)
  const [lastLoadedDay, setLastLoadedDate] = useState<number>(0)
  // TODO: split lockupInfo to general info and stats info, load and listen them separately
  const lockupInfoRef = useRef<LockupStatsInfo>()

  const [isLoading, setIsLoading] = useState(false)
  const [isActual, setIsActual] = useState(false)
  const [error, setError] = useState<unknown>()

  const { createCancelableFactory } = useCancelableFactory()
  const { memoizedRequest, deleteRequest } = useRequestMemo()

  function requestLockupInfo(): Destructor {
    const contractAddress = tronSets[chainId].unlock
    const requestKey = `${account}_${chainId}_${contractAddress}`

    const { makeCancellable, cancel } = createCancelableFactory(() => deleteRequest(requestKey))

    const emitCancellable = async (): Promise<void> => {
      try {
        setIsLoading(true)
        setError(false)
        setLastLoadedDate(0)
        setLastDay(0)
        lockupInfoRef.current = undefined
        const makeRequest = () => PrivatePresaleLockupAPI.lockupInfo(library, contractAddress, account, 0, 1)

        const response = await makeCancellable(memoizedRequest(requestKey, makeRequest))
        lockupInfoRef.current = response
        setLastLoadedDate(1)

        const duration = () => {
          const now = moment.now()
          switch (true) {
            case now > response.endPromoTimestamp:
              return response.endPromoTimestamp - START_PROMO_TIMESTAMP
            case now - START_PROMO_TIMESTAMP >= 0:
              return now - START_PROMO_TIMESTAMP
            default:
              return 1
          }
        }

        const passed = Math.floor(moment.duration(duration()).asDays() + 1)

        if (passed === 1) {
          setLockupInfo(response)
          setIsActual(true)
        }
        setLastDay(passed)
        setIsLoading(false)
      } catch (error) {
        if (isHandleableTransactionError(error)) {
          setError(true)
          setIsLoading(false)
          console.error('useLockupStatsInfo', error)
        }
      }
    }

    emitCancellable()
    return cancel
  }

  function requestStatistic(): Destructor {
    const contractAddress = tronSets[chainId].unlock
    const requestKey = `${contractAddress}_${account}_${lastLoadedDay}`

    const { makeCancellable, cancel } = createCancelableFactory(() => deleteRequest(requestKey))

    const emitCancellable = async (): Promise<void> => {
      try {
        if (lastDay === 0) {
          return
        }

        if (lastDay <= lastLoadedDay) {
          setLockupInfo(lockupInfoRef.current && { ...lockupInfoRef.current })
          setIsActual(true)
          return
        }
        setIsLoading(true)
        setError(false)

        const nextEndDay = lastDay - lastLoadedDay > MAX_STATIC ? lastLoadedDay + MAX_STATIC : lastDay

        const makeRequest = () =>
          PrivatePresaleLockupAPI.lockupInfo(library, contractAddress, account, lastLoadedDay, nextEndDay)
        const response = await makeCancellable(memoizedRequest(requestKey, makeRequest))

        setLastLoadedDate(nextEndDay)
        lockupInfoRef.current = lockupInfoRef.current && {
          ...lockupInfoRef.current,
          stats: [...lockupInfoRef.current.stats, ...response.stats],
        }
        setIsLoading(false)
      } catch (error) {
        if (isHandleableTransactionError(error)) {
          setIsActual(true)
          setIsLoading(false)
          console.error('useLockupStatsInfo', error)
        }
      }
    }

    emitCancellable()
    return cancel
  }

  useEffect(() => setLockupInfoCache(wrapLockupInfo(lockupInfo)), [lockupInfo])

  useEffect(() => {
    setIsActual(false)
    return requestLockupInfo()
  }, [library, account, chainId])

  useEffect(() => requestStatistic(), [lastLoadedDay, lastDay])

  const refreshLockupInfo = useCallback<() => Destructor>(requestLockupInfo, [library, account, chainId])

  return useMemo(
    () => ({
      value: lockupInfo,
      error,
      isActual,
      isLoading,
      softRefresh: refreshLockupInfo,
      hardRefresh: () => {
        setIsActual(false)
        return requestLockupInfo()
      },
    }),
    [lockupInfo, error, isLoading, isActual, refreshLockupInfo],
  )
}

function wrapLockupInfo(lockupInfo: LockupStatsInfo | undefined): Cached<LockupStatsInfo | undefined> {
  return lockupInfo
    ? {
        ...lockupInfo,
        stats: lockupInfo.stats.map((el) => ({ ...el, totalLockedTokens: el.totalLockedTokens.toString() })),
      }
    : undefined
}

function unwrapLockupInfo(wrappedLockupInfo: Cached<LockupStatsInfo | undefined>): LockupStatsInfo | undefined {
  return wrappedLockupInfo
    ? {
        ...wrappedLockupInfo,
        stats: wrappedLockupInfo.stats.map((el) => ({ ...el, totalLockedTokens: fromBNish(el.totalLockedTokens) })),
      }
    : undefined
}
