import { Link } from '@material-ui/core'
import { TERMS_OF_USE } from 'assets/constants'
import secondaryDaisyLogo from 'assets/icons/secondaryDaisyLogo.svg'
import { SocialIcons } from 'components/socialIcons'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'

export const Footer = (): JSX.Element => {
  const { t } = useTranslation()

  const classes = useStyles()
  const year = new Date().getFullYear()

  return (
    <footer className={classes.root}>
      <div className={classes.maxWidth}>
        <img className={classes.marginContainer} src={secondaryDaisyLogo} alt="daisy logo icon" />
        <span className={classes.primaryTitle}>{t('footer_copyright', { year })}</span>
      </div>

      <div className={classes.column}>
        <div className={classes.socialContainer}>
          <SocialIcons />
          <Link href={TERMS_OF_USE} className={classes.link} rel="noreferrer" target="_blank">
            {t('footer_terms_of_use')}
          </Link>
        </div>
        <span className={classes.secondaryTitle}>{t('footer_copyright', { year })}</span>
      </div>
    </footer>
  )
}
