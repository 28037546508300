import { makeStyles } from '@material-ui/core'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 0px 143px',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '720px',
    gap: '16px',
  },
  title: {
    color: colors.white,
    fontSize: '1.4rem',
    fontWeight: 400,
    marginBottom: '8px',
  },
  primaryInfoText: {
    fontSize: '1rem',
    color: colors.white,
    marginBottom: '10px',
  },
  secondaryInfoText: {
    color: colors.primaryGreen,
    fontWeight: 400,
  },
})
