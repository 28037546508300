import { WithChildrenProps } from 'core/interfaces/hooks/strict/common/tron'
import { useLockupStatsInfo } from 'hooks/liquidPromo/useLockupStatsInfo'
import { LockupStatsInfoStrictContextProvider } from 'hooks/strict/privatePresaleLockup/useStrictLockupInfo'
import { PromoPageSkeleton } from 'pages/liquidPromo/skeletons/promoPage'
import { useEffect } from 'react'
import { useRefresh } from 'services/RefreshContextProvider'

export const LockupInfoStatsWrapper = ({ children }: WithChildrenProps): JSX.Element => {
  const { slowRefresh } = useRefresh()
  const { value, error, softRefresh } = useLockupStatsInfo()
  useEffect(() => softRefresh(), [slowRefresh])

  if (!!error || !value) {
    return <PromoPageSkeleton />
  }

  return <LockupStatsInfoStrictContextProvider providedValue={value}>{children}</LockupStatsInfoStrictContextProvider>
}
