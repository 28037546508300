import { ClaimSkeletonPage } from 'pages/claimSwitch/skeletons/claimPageSkeleton'
import { StrictTronContext } from 'core/interfaces/hooks/strict/common/tron'
import { useLockupInfo } from 'hooks/claim/useLockupInfo'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import { useTRXBalance } from 'hooks/trc20/useTRX'
import { TronConfirm } from 'pages/claimSwitch/tronConfirm'
import { MainSwitch } from 'pages/mainSwitch'
import React, { useEffect } from 'react'
import { useTronWeb } from 'services/AccountService'
import { useRefresh } from 'services/RefreshContextProvider'
import { TronStrictContextProvider } from 'services/strict/common/tron'
import { TrxBalanceStrictContextProvider } from 'services/strict/common/trxBalance'
import { LockupInfoStrictContextProvider } from 'services/strict/lockupInfo/lockupInfo'
import { Claim } from './claim'
import { PoolBalanceStrictWrapper } from './wrappers'

// TODO: move logic to Claim component and delete this component
export const ClaimSwitch = (): JSX.Element => {
  const { chain, account, tronWeb } = useTronWeb()

  if (!chain || !account || !tronWeb) {
    return <MainSwitch />
  }

  const provideValue: StrictTronContext = { chainId: chain, account, tronWeb }
  return (
    <TronStrictContextProvider providedValue={provideValue}>
      <ClaimTemplate />
    </TronStrictContextProvider>
  )
}

const ClaimTemplate = () => {
  const { chainId, account } = useStrictTron()
  const { fastRefresh } = useRefresh()
  const [lockupInfo, refreshLockupInfo] = useLockupInfo()
  const [balanceInTRX, refreshBalanceInTRX] = useTRXBalance()

  useEffect(() => {
    refreshLockupInfo()
    refreshBalanceInTRX()
  }, [fastRefresh, account, chainId])

  if (!lockupInfo || !balanceInTRX) {
    return <ClaimSkeletonPage />
  }

  const isTronConfirm = !lockupInfo.notConfirmedTokens.isZero() || !lockupInfo.notConfirmedReferralTokens.isZero()

  return (
    <TrxBalanceStrictContextProvider providedValue={balanceInTRX}>
      <LockupInfoStrictContextProvider providedValue={lockupInfo}>
        {isTronConfirm ? (
          <TronConfirm />
        ) : (
          <PoolBalanceStrictWrapper>
            <Claim />
          </PoolBalanceStrictWrapper>
        )}
      </LockupInfoStrictContextProvider>
    </TrxBalanceStrictContextProvider>
  )
}
