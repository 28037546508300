import { MIN_TRX_CLAIM, TRX_PRECISION } from 'assets/constants'
import { TFunction } from 'react-i18next'
import { BN, formattedDecimals } from 'utils/tsUtils'
import { ClaimStateEnum } from 'core/enums/components/buttons/mainButton/ClaimStateEnum'

export function confirmValidateState(balanceInTRX: BN): ClaimStateEnum {
  if (balanceInTRX.lt(MIN_TRX_CLAIM)) {
    return ClaimStateEnum.ERROR_INSUFFICIENT_TRX_BALANCE
  }
  return ClaimStateEnum.SUCCESS
}

export function confirmStateErrorMessage(state: ClaimStateEnum, t: TFunction<'translation'>): string {
  switch (state) {
    case ClaimStateEnum.ERROR_INSUFFICIENT_TRX_BALANCE:
      return t('buttons_error_insufficient_trx_balance', {
        amount: formattedDecimals(MIN_TRX_CLAIM, TRX_PRECISION),
      })
    case ClaimStateEnum.SUCCESS:
    default:
      return ''
  }
}
