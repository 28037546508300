import Button from '@material-ui/core/Button'
import { DAISY_PRECISION } from 'assets/constants'
import daisyTokenGreen from 'assets/icons/daisyTokenGreen.svg'
import tronLinkIcon from 'assets/icons/tronLinkIcon.svg'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { transformAddress } from 'utils/transformAddress'
import { formattedDecimals } from 'utils/tsUtils'
import { useStyles } from './styles'
import { WalletButtonProps } from 'core/interfaces/components/buttons/walletButton/WalletButtonProps'
import { useRefresh } from 'services/RefreshContextProvider'
import { useBalance } from 'hooks/trc20/useTRC10'
import { tronSets } from 'contracts/sets/sets'
import { useTronWeb } from 'services/AccountService'

export const WalletButton = ({ onClick }: WalletButtonProps): JSX.Element => {
  const { t } = useTranslation()
  const { account, chain } = useTronWeb()
  const classes = useStyles()
  const { slowRefresh } = useRefresh()
  const [balance, refreshBalance] = useBalance(tronSets[chain ?? 1].DAISY, account ?? '')

  useEffect(() => void refreshBalance(), [slowRefresh])

  const formattedDaisyBalance = !!balance
    ? t('common_daisy_with_amount', { amount: formattedDecimals(balance, DAISY_PRECISION) })
    : '…'

  return (
    <div className={classes.root} onClick={onClick}>
      <Button classes={{ root: classes.primaryButton }}>
        <img className={classes.imageMarginContainer} src={daisyTokenGreen} alt="daisyTokenGreen" />
        <span className={classes.buttonLabel}>{formattedDaisyBalance}</span>
      </Button>
      <Button classes={{ root: classes.secondaryButton }}>
        <img src={tronLinkIcon} alt="tronLinkIcon" />
        {transformAddress(account ?? '…')}
      </Button>
    </div>
  )
}
