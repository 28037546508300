import { ClaimSkeletonPage } from 'pages/claimSwitch/skeletons/claimPageSkeleton'
import { tronSets } from 'contracts/sets/sets'
import { WithChildrenProps } from 'core/interfaces/hooks/strict/common/tron'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import { useBalance } from 'hooks/trc20/useTRC10'
import React, { useEffect } from 'react'
import { useRefresh } from 'services/RefreshContextProvider'
import { PoolsBalanceStrictContextProvider } from 'services/strict/poolsBalance/poolsBalance'

export const PoolBalanceStrictWrapper = ({ children }: WithChildrenProps): JSX.Element => {
  const { chainId } = useStrictTron()
  const { slowRefresh } = useRefresh()
  const { DAISY, unlock, additionalPools, otherAdditionalPools } = tronSets[chainId]

  const [mainPoolBalance, refreshMainPoolBalance] = useBalance(DAISY, unlock)
  const [additionalPool1Balance, refreshAdditionalPool1Balance] = useBalance(DAISY, additionalPools[0])
  const [additionalPool2Balance, refreshAdditionalPool2Balance] = useBalance(DAISY, additionalPools[1])
  const [additionalPool3Balance, refreshAdditionalPool3Balance] = useBalance(DAISY, additionalPools[2])
  const [additionalPool4Balance, refreshAdditionalPool4Balance] = useBalance(DAISY, additionalPools[3])
  const [otherAdditionalPool1Balance, refreshOtherAdditionalPool1Balance] = useBalance(DAISY, otherAdditionalPools[0])
  const [otherAdditionalPool2Balance, refreshOtherAdditionalPool2Balance] = useBalance(DAISY, otherAdditionalPools[1])

  useEffect(() => {
    refreshMainPoolBalance()
    refreshAdditionalPool1Balance()
    refreshAdditionalPool2Balance()
    refreshAdditionalPool3Balance()
    refreshAdditionalPool4Balance()
    refreshOtherAdditionalPool1Balance()
    refreshOtherAdditionalPool2Balance()
  }, [slowRefresh, DAISY, unlock, additionalPools])

  if (
    !mainPoolBalance ||
    !additionalPool1Balance ||
    !additionalPool2Balance ||
    !additionalPool3Balance ||
    !additionalPool4Balance ||
    !otherAdditionalPool1Balance ||
    !otherAdditionalPool2Balance
  ) {
    return <ClaimSkeletonPage />
  }

  const provideValue = {
    mainPoolBalance,
    additionalPool1Balance,
    additionalPool2Balance,
    additionalPool3Balance,
    additionalPool4Balance,
    otherAdditionalPool1Balance,
    otherAdditionalPool2Balance,
  }

  return <PoolsBalanceStrictContextProvider providedValue={provideValue}>{children}</PoolsBalanceStrictContextProvider>
}
