import { toast } from 'react-toastify'
import { ToastStateEnum } from 'core/enums/utils/ToastStateEnum'
import React from 'react'

export function toastStateMessage(type: ToastStateEnum, message: string): React.ReactText | null {
  switch (type) {
    case ToastStateEnum.SUCCESS:
      return toast.success(message)
    case ToastStateEnum.ERROR:
      return toast.error(message)
    case ToastStateEnum.INFO:
      return toast.info(message)
    default:
      return null
  }
}
