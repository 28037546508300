import { secondsToMillis } from './dateFormatter'
import { BN, fromBNish } from './tsUtils'

const ROUND_UP_TO_THOUSANDS = 10000
export const progressBarPercent = (
  max: BN,
  current: BN,
  formatOptions: Intl.NumberFormatOptions = { maximumFractionDigits: 2, minimumFractionDigits: 0 },
): { currentPercent: number; formattedPercent: string; lessThanOne: boolean } => {
  const percent: BN = !max.isZero() ? current.mul(fromBNish(ROUND_UP_TO_THOUSANDS)).div(max) : fromBNish(0)
  const currentPercent: number = percent.gt(fromBNish(ROUND_UP_TO_THOUSANDS)) ? 100 : Number(percent) / 100
  const formattedPercent = currentPercent.toLocaleString(undefined, formatOptions)
  const lessThanOne = !current.isZero() && currentPercent < 0.01
  return { currentPercent, formattedPercent, lessThanOne }
}

export const timerPercent = (now: number, endDataInTimestamp: number, startDataInTimestamp: number): number => {
  const percent =
    100 -
    ((secondsToMillis(endDataInTimestamp) - now) * 100) /
      (secondsToMillis(endDataInTimestamp) - secondsToMillis(startDataInTimestamp))
  return percent > 100 ? 100 : Number(percent)
}

export const formattedPercentFromContract = (percentFromContract: number): number => {
  return percentFromContract / 100
}
