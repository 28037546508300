import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  primaryButton: {
    minWidth: '320px',
    minHeight: '93px',
    '&:disabled': {
      fontSize: '0.9rem',
      maxWidth: '320px',
      padding: '0px 20px',
    },
    '@media (max-width: 600px)': {
      minWidth: '100%',
      '&:disabled': {
        maxWidth: '100%',
      },
    },
  },
  subTitle: {
    fontSize: '1rem',
    color: colors.black,
  },
  label: {
    color: colors.black,
    fontSize: '1.7rem',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 600px)': {
      minWidth: '100%',
    },
  },
  link: {
    textDecoration: 'none',
  },
})
