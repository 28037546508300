import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './styles'
import cross from 'assets/icons/cross.svg'
import { DAISY_APP } from 'assets/constants'

export const AttentionBlock = (): JSX.Element => {
  const [isShown, setIsShown] = useState(true)
  const classes = useStyles({ isShown })

  return (
    <div className={classes.root}>
      <span className={classes.primaryTitle}>
        <Trans
          i18nKey="header_attention_message"
          components={[
            <a href={DAISY_APP} className={classes.secondaryTitle} target="_blank" rel="noreferrer" key="0" />,
          ]}
        />
      </span>
      <button className={classes.cross} onClick={() => setIsShown(false)}>
        <img src={cross} alt="cross" />
      </button>
    </div>
  )
}
