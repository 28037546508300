import { TronWebAPI } from 'api/tron/TronAPI'
import { BigNumber } from 'ethers'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import { useCallback, useState } from 'react'
import { VoidPromiseCallback } from 'utils/tsUtils'

const TRXBalanceCache: Record<string, BigNumber | undefined> = {}

export function useTRXBalance(): [BigNumber | undefined, VoidPromiseCallback] {
  const { account, chainId, tronWeb } = useStrictTron()
  const [balance, setBalance] = useState<BigNumber | undefined>(TRXBalanceCache[`${chainId}${account}`])

  async function request(): Promise<void> {
    try {
      const _balance = await TronWebAPI.getBalance(tronWeb, account)
      TRXBalanceCache[`${chainId}${account}`] = _balance
      setBalance(_balance)
    } catch (error) {
      console.error('useTRXBalance', error)
    }
  }

  const refreshBalance = useCallback<VoidPromiseCallback>(request, [tronWeb, account, chainId])
  return [balance, refreshBalance]
}
