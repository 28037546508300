import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  skeleton: {
    minHeight: '272px',
    minWidth: '520px',
    marginBottom: '30px',
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
  boldTitle: {
    fontWeight: 400,
    fontSize: '1rem',
    color: colors.white,
  },
  boldInfo: {
    fontWeight: 400,
    fontSize: '1rem',
    color: colors.green_74_255_151,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  infoContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '18px',
    margin: '24px auto 0px',
  },
  сlaimInfoContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '18px',
    margin: '12px auto 0px',
  },
})
