import { StrictContextProps } from 'core/interfaces/hooks/strict/common/tron'
import React, { useMemo } from 'react'
import { BN } from 'utils/tsUtils'

export interface PoolBalanceStrict {
  mainPoolBalance: BN
  additionalPool1Balance: BN
  additionalPool2Balance: BN
  additionalPool3Balance: BN
  additionalPool4Balance: BN
  otherAdditionalPool1Balance: BN
  otherAdditionalPool2Balance: BN
}
export const PoolsBalanceStrictContext = React.createContext<unknown>(undefined)

export const PoolsBalanceStrictContextProvider = ({
  children,
  providedValue,
}: StrictContextProps<PoolBalanceStrict>): JSX.Element => {
  useMemo(
    () => providedValue,
    [
      providedValue.mainPoolBalance.toString(),
      providedValue.additionalPool1Balance.toString(),
      providedValue.additionalPool2Balance.toString(),
      providedValue.additionalPool3Balance.toString(),
      providedValue.additionalPool4Balance.toString(),
      providedValue.otherAdditionalPool1Balance.toString(),
      providedValue.otherAdditionalPool2Balance.toString(),
    ],
  )

  return <PoolsBalanceStrictContext.Provider value={providedValue}>{children}</PoolsBalanceStrictContext.Provider>
}
