import { StrictTronContext } from 'core/interfaces/hooks/strict/common/tron'
import { MainSwitch } from 'pages/mainSwitch'
import { useTronWeb } from 'services/AccountService'
import { TronStrictContextProvider } from 'services/strict/common/tron'
import { LiquidPromo } from './promo'

export const LiquidPromoSwitch = (): JSX.Element => {
  const { chain, account, tronWeb } = useTronWeb()
  if (!chain || !account || !tronWeb) {
    return <MainSwitch />
  }

  const provideValue: StrictTronContext = { account, chainId: chain, tronWeb }
  return (
    <TronStrictContextProvider providedValue={provideValue}>
      <LiquidPromo />
    </TronStrictContextProvider>
  )
}
