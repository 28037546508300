import { makeStyles } from '@material-ui/core'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.5fr 1fr',

    '@media (max-width: 600px)': {
      gridTemplateColumns: '1fr',
    },
  },
  row: {
    display: 'flex',
    gap: '4px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
  },
  title: {
    fontSize: '1rem',
    color: colors.secondaryLightGrey,
  },
  info: {
    fontSize: '1.8rem',
    color: colors.primaryInfoText,

    '@media (max-width: 800px)': {
      fontSize: '1.4rem',
    },
  },
  separator: {
    height: '64px',
    borderRight: `1px solid ${colors.primaryDarkGrey}`,
    margin: 'auto',

    '@media (max-width: 600px)': {
      border: 'none',
      borderTop: `1px solid ${colors.primaryDarkGrey}`,
      margin: '10px 0',
      width: '100%',
      height: '100%',
    },
  },
})
