import { BigNumber, ethers } from 'ethers'

export type Nullable<T> = T | null | undefined
export type BNish = BigNumber | string | number
export type BN = BigNumber
export type VoidPromiseCallback = () => Promise<void>

export function formattedDecimals(amount: BNish, decimals: number, symbol?: string, maxDecimalPlaces = 6): string {
  const units = ethers.utils.formatUnits(amount === '' ? '0' : amount, decimals)
  const unitsNumber = new Intl.NumberFormat(navigator.languages as string[], {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDecimalPlaces,
  }).format(+units)
  if (symbol && symbol.length > 0) {
    return `${unitsNumber} ${symbol}`
  }
  return unitsNumber
}
export function toDecimals(amount: BNish, decimals: number): string {
  const units = ethers.utils.formatUnits(amount, decimals)
  const lastIndex = units.lastIndexOf('.')
  if (units.slice(lastIndex) == '.0') {
    return units.slice(0, lastIndex)
  }
  return units
}

export function fromBNish(amount: BNish): BigNumber {
  return BigNumber.from(amount)
}

export function fromDecimals(amount: string, decimals: number): BigNumber {
  const _amount = amount.trim()
  return ethers.utils.parseUnits(_amount === '' ? '0' : _amount, decimals)
}

export function fromBNToString(amount: BN, decimal: number): string {
  return amount.div(fromBNish('10').pow(decimal)).toString()
}
