import React, { useState, useEffect, useContext, useCallback } from 'react'

const TIME_REFRESH_INTERVAL = 1000
const FAST_INTERVAL = 30 * 1000
const SLOW_INTERVAL = 60 * 1000

const RefreshContext = React.createContext({
  slow: 0,
  fast: 0,
  forceFastRefreshCallback: () => {
    /* empty */
  },
  timeRefresh: 0,
})

const useRefresh = (): {
  fastRefresh: number
  slowRefresh: number
  forceFastRefreshCallback: () => void
  timeRefresh: number
} => {
  const { fast, slow, forceFastRefreshCallback, timeRefresh } = useContext(RefreshContext)
  return {
    fastRefresh: fast,
    slowRefresh: slow,
    forceFastRefreshCallback: forceFastRefreshCallback,
    timeRefresh: timeRefresh,
  }
}
// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic refresh
const RefreshContextProvider = ({ children }: { children: JSX.Element[] | JSX.Element }): JSX.Element => {
  const [slow, setSlow] = useState(0)
  const [fast, setFast] = useState(0)
  const [timeRefresh, setTimeRefresh] = useState(0)

  useEffect(() => {
    const interval = setInterval(async () => {
      setFast((prev) => prev + 1)
    }, FAST_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      setSlow((prev) => prev + 1)
    }, SLOW_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  const forceFastRefreshCallback = useCallback(() => {
    setFast((prev) => prev + 1)
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      setTimeRefresh((prev) => prev + 1)
    }, TIME_REFRESH_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  return (
    <RefreshContext.Provider value={{ slow, fast, forceFastRefreshCallback, timeRefresh }}>
      {children}
    </RefreshContext.Provider>
  )
}

export { RefreshContext, RefreshContextProvider, useRefresh }
