import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    padding: 0,
    marginBottom: 20,
  },
  paperWidthSm: {
    background: colors.primaryDarkBlue,
    border: `3px solid ${colors.primaryGreen}`,
    borderRadius: '10px',
    padding: '27px 35px 20px 37px',
    maxWidth: '520px',
  },
  primaryTitle: {
    fontSize: '2rem',
    fontWeight: 400,
    color: colors.white,
    textTransform: 'uppercase',
  },
  secondaryTitle: {
    fontSize: '1.2rem',
    color: colors.white,
  },
  primaryInfo: {
    fontSize: '1.1rem',
    color: colors.primaryOrange,
  },
  dialogContent: {
    width: '-webkit-fill-available',
    padding: '8px 18px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'end',
    fontSize: '0.875rem',
    borderRadius: '5px',
    border: ` 2px solid ${colors.primaryGreen}`,
    cursor: 'pointer',
    background: colors.transparent,
    boxShadow: `0px 4px 6px ${colors.black_opacity_0_25}`,
    margin: '5px 0px 15px',
  },
  scrollContainer: {
    maxHeight: '100px',
    width: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    overflowY: 'auto',
    paddingRight: '15px',
    /* ScrollBar */
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
      borderRadius: '5px',
      background: colors.secondaryDarkGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: colors.primaryGreen,
      '&:hover': {
        backgroundColor: colors.primaryGreen,
      },
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      background: colors.secondaryDarkGrey,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0px',
  },
  imageColor: {
    color: colors.primaryOrange,
    fontSize: '1rem',
  },
  onClickTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5px',
    cursor: 'pointer',
  },
  onClickText: {
    fontWeight: 400,
    fontSize: '1rem',
    color: colors.white,
    textDecoration: 'underline',
  },
})
