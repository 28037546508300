import { StrictTronContext } from 'core/interfaces/hooks/strict/common/tron'
import { MainSwitch } from 'pages/mainSwitch'
import React from 'react'
import { useTronWeb } from 'services/AccountService'
import { TronStrictContextProvider } from 'services/strict/common/tron'
import { Exchange } from './exchange'

export const ExchangeSwitch = (): JSX.Element => {
  const { chain, account, tronWeb } = useTronWeb()
  if (!chain || !account || !tronWeb) {
    return <MainSwitch />
  }

  const provideValue: StrictTronContext = { account, chainId: chain, tronWeb }
  return (
    <TronStrictContextProvider providedValue={provideValue}>
      <Exchange />
    </TronStrictContextProvider>
  )
}
