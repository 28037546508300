import React from 'react'
import { Trans } from 'react-i18next'
import { useStyles } from './styles'
import { BannerProps } from 'core/interfaces/components/banner/props/BannerProps'

export const Banner = ({ transactionKey }: BannerProps): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.bannerContainer}>
        <div className={classes.titleWrapper}>
          <span>
            <Trans i18nKey={transactionKey} components={[<span className={classes.primaryTitle} key="0" />]} />
          </span>
        </div>
      </div>
    </div>
  )
}
