import { MIN_TRX_CLAIM, TRX_PRECISION } from 'assets/constants'
import { ClaimStateEnum } from 'core/enums/components/buttons/mainButton/ClaimStateEnum'
import { LockupInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { TFunction } from 'react-i18next'
import { PoolBalanceStrict } from 'services/strict/poolsBalance/poolsBalance'
import { BN, formattedDecimals } from 'utils/tsUtils'

export function claimValidateState(
  balanceInTRX: BN,
  lockupInfo: LockupInfo,
  poolsBalance: PoolBalanceStrict,
): ClaimStateEnum {
  if (lockupInfo.availableToClaimAmount.isZero()) {
    return ClaimStateEnum.ERROR_CLAIM_UNAVAILABLE
  }
  if (balanceInTRX.lt(MIN_TRX_CLAIM)) {
    return ClaimStateEnum.ERROR_INSUFFICIENT_TRX_BALANCE
  }

  if (
    poolsBalance.mainPoolBalance.lt(lockupInfo.mainPool.availableToClaim) ||
    poolsBalance.additionalPool1Balance.lt(lockupInfo.additionalPools[0].availableToClaim) ||
    poolsBalance.additionalPool2Balance.lt(lockupInfo.additionalPools[1].availableToClaim) ||
    poolsBalance.additionalPool3Balance.lt(lockupInfo.additionalPools[2].availableToClaim) ||
    poolsBalance.additionalPool4Balance.lt(lockupInfo.additionalPools[3].availableToClaim) ||
    poolsBalance.otherAdditionalPool1Balance.lt(lockupInfo.otherAdditionalPools[0].availableToClaim) ||
    poolsBalance.otherAdditionalPool2Balance.lt(lockupInfo.otherAdditionalPools[1].availableToClaim)
  ) {
    return ClaimStateEnum.ERROR_INSUFFICIENT_DAISY_BALANCE
  }
  return ClaimStateEnum.SUCCESS
}

export function claimStateErrorMessage(state: ClaimStateEnum, t: TFunction<'translation'>): string {
  switch (state) {
    case ClaimStateEnum.ERROR_CLAIM_UNAVAILABLE:
      return t('buttons_error_all_claim')
    case ClaimStateEnum.ERROR_INSUFFICIENT_TRX_BALANCE:
      return t('buttons_error_insufficient_trx_balance', {
        amount: formattedDecimals(MIN_TRX_CLAIM, TRX_PRECISION),
      })
    case ClaimStateEnum.ERROR_INSUFFICIENT_DAISY_BALANCE:
      return t('buttons_error_insufficient_daisy_balance')
    case ClaimStateEnum.SUCCESS:
    default:
      return ''
  }
}
