import { useStyles } from './styles'

export const CommonInfoDescription = ({ description, title }: { description: string; title: string }): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.infoTextContainer}>
      <span className={classes.primaryInfoText}>{title}</span>
      <span className={classes.secondaryInfoText}>{description}</span>
    </div>
  )
}
