import { DAISY_PRECISION } from 'assets/constants'
import { ClaimPoolCardAlreadyToClaimProps } from 'core/interfaces/components/claimPoolCard/ClaimPoolCardProps'
import React from 'react'
import { Trans } from 'react-i18next'
import { formattedDecimals } from 'utils/tsUtils'
import { useStyles } from '../claimPoolCard/styles'

export const ClaimPoolCardAlreadyToClaim = ({
  title,
  totalAmount,
  availableDaisy,
}: ClaimPoolCardAlreadyToClaimProps): JSX.Element => {
  const classes = useStyles()
  const formattedTotalAmount = formattedDecimals(totalAmount, DAISY_PRECISION)
  const formattedAvailableAmount = formattedDecimals(availableDaisy, DAISY_PRECISION)

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <span className={classes.title}>{title}</span>

        <div className={classes.column}>
          <span className={classes.primaryInfoText}>
            <Trans
              i18nKey={'claim_page_total_amount_daisy'}
              components={[<span className={classes.secondaryInfoText} key="0" />]}
            />
          </span>
          <span className={classes.primaryAmountText}>{formattedTotalAmount}</span>
        </div>

        <div className={classes.separator} />

        <div className={classes.row}>
          <span className={classes.primaryInfoText}>
            <Trans
              i18nKey="claim_page_available_daisy"
              components={[<span className={classes.secondaryInfoText} key="0" />]}
            />
          </span>
          <span className={classes.primaryAmountText}>{formattedAvailableAmount}</span>
        </div>
      </div>
    </div>
  )
}
