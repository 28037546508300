import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 0px 100px',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    borderRadius: '30px',
    background: colors.black,
    minHeight: '90px',
    minWidth: '520px',
    maxWidth: '720px',
    padding: '33px 35px',
    marginBottom: '30px',
    boxSizing: 'border-box',
    '@media (max-width: 600px)': {
      minWidth: '100%',
      maxWidth: 'unset',
    },
  },
  primaryTitle: {
    color: colors.secondaryLightGrey,
    marginBottom: '5px',
    fontSize: '1.28rem',
  },
  secondaryTitle: {
    fontSize: '1.28rem',
    color: colors.primaryGreen,
  },
  titleTextContainer: {
    width: '-webkit-fill-available',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    justifyContent: 'flex-end',
    width: '-webkit-fill-available',
    marginTop: '20px',
    alignItems: 'flex-start',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  link: {
    fontSize: '1rem',
    color: colors.primaryGreen,
    marginRight: '5px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  titleMargin: {
    marginTop: '15px',
  },
  linkTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      width: 'inherit',
    },
  },
})
