import { LockupStatsInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { StrictContextProps } from 'core/interfaces/hooks/strict/common/tron'
import { useStrictContext } from 'hooks/common/useStrictContext'
import React from 'react'

export const LockupStatsInfoStrictContext = React.createContext<unknown>(undefined)

export const LockupStatsInfoStrictContextProvider = ({
  children,
  providedValue,
}: StrictContextProps<LockupStatsInfo>): JSX.Element => {
  return <LockupStatsInfoStrictContext.Provider value={providedValue}>{children}</LockupStatsInfoStrictContext.Provider>
}

export const useStrictLiquidLockupInfo = (): LockupStatsInfo => {
  return useStrictContext(LockupStatsInfoStrictContext, 'LockupStatsInfoStrictContext') as LockupStatsInfo
}
