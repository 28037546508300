import { DAISY_PRECISION } from 'assets/constants'
import { CommonCard } from 'components/commonCard'
import { useStrictLiquidLockupInfo } from 'hooks/strict/privatePresaleLockup/useStrictLockupInfo'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { formattedDecimals, fromBNish } from 'utils/tsUtils'
import { AmountWithLogo } from '../amountWithLogo'
import { useStyles } from './styles'

export const PromoInfo = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const lockupInfo = useStrictLiquidLockupInfo()

  const totalStatic = useMemo(() => {
    return lockupInfo.stats.length > 0
      ? lockupInfo.stats.reduce((previous, current) => ({
          totalUsers: previous.totalUsers + current.totalUsers,
          totalLockedTokens: previous.totalLockedTokens.add(current.totalLockedTokens),
        }))
      : { totalUsers: 0, totalLockedTokens: fromBNish(0) }
  }, [lockupInfo])

  return (
    <CommonCard>
      <div className={classes.content}>
        <div className={classes.column}>
          <span className={classes.title}>{t('promo_total_users')}</span>
          <span className={classes.info}>
            {totalStatic.totalUsers.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </span>
        </div>
        <div className={classes.separator} />
        <div className={classes.column}>
          <span className={classes.title}>{t('promo_tvl')}</span>
          <div className={classes.row}>
            <AmountWithLogo amount={formattedDecimals(totalStatic.totalLockedTokens, DAISY_PRECISION)} />
          </div>
        </div>
      </div>
    </CommonCard>
  )
}
