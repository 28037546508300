import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 0px 100px',
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 600px)': {
      alignItems: 'stretch',
    },
  },
  card: {
    borderRadius: '30px',
    background: colors.black,
    minHeight: '90px',
    minWidth: '450px',
    maxWidth: '720px',
    padding: '33px 35px',
    marginBottom: '30px',
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    '@media (max-width: 600px)': {
      marginLeft: '0px',
    },
  },
  primaryInfo: {
    marginTop: '20px',
    color: colors.white,
  },
})
