import { LockupStatsInfo } from 'core/interfaces/hooks/claim/LockupInfo'
import { secondsToMillis } from 'utils/dateFormatter'
import { BN } from 'utils/tsUtils'

type DecodedLockupInfo = [
  [BN, BN, BN, BN, BN, BN, number[], number[], number[], number, number, number, BN, [number, BN][]],
]

export function parseOldLockupInfo(decodedLockupInfo: DecodedLockupInfo): LockupStatsInfo {
  return {
    endPromoTimestamp: secondsToMillis(decodedLockupInfo[0][10]),
    stats: decodedLockupInfo[0][13]
      ? decodedLockupInfo[0][13].map((info) => ({
          totalUsers: info[0],
          totalLockedTokens: info[1],
        }))
      : [],
  }
}
