import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  primaryButton: {
    minWidth: '320px',
    minHeight: '93px',
    background: colors.grey_opacity_0_70,
    transform: 'none',
    borderRadius: '100px',
    '@media (max-width: 600px)': {
      minWidth: '100%',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    '@media (max-width: 600px)': {
      minWidth: '250px',
    },
    '@media (max-width: 350px)': {
      minWidth: '100%',
    },
  },
})
