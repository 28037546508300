import { ClaimPoolCard } from 'components/claimPoolCard'
import { ClaimPoolCardAlreadyToClaim } from 'components/claimPoolCardAlreadyToClaim'
import { CommonCard } from 'components/commonCard'
import { useStrictLockupInfo } from 'hooks/strict/lockupInfo/useStrictlockupInfo'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useStyles } from './styles'

export const ClaimPoolSection = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { additionalPools, otherAdditionalPools } = useStrictLockupInfo()

  const poolsInfo = [
    t('claim_page_spillover_maker_pool_1'),
    t('claim_page_spillover_maker_pool_2'),
    t('claim_page_pacesetter_gold_pool'),
    t('claim_page_pacesetter_leadership_pool'),
  ]

  const poolsInfoAlreadyToClaim = [t('claim_page_momentum_pool'), t('claim_page_tiers__pool')]

  return (
    <CommonCard>
      <div className={classes.primaryText}>
        <Trans
          i18nKey="claim_page_claim_your_daisy"
          components={[<span className={classes.secondaryText} key="0" />]}
        />
      </div>
      <div className={classes.content}>
        {additionalPools.map((el, index) => (
          <ClaimPoolCard
            key={index}
            title={poolsInfo[index]}
            distribution={el.claimed}
            availableDaisy={el.availableToClaim}
            totalAmount={el.total}
            locked={el.locked}
          />
        ))}
        {poolsInfoAlreadyToClaim.map((el, index) => (
          <ClaimPoolCardAlreadyToClaim
            key={index}
            title={el}
            totalAmount={otherAdditionalPools[index].total}
            availableDaisy={otherAdditionalPools[index].availableToClaim}
          />
        ))}
      </div>
    </CommonCard>
  )
}
