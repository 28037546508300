import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 0px 100px',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',
    '&>img': {
      '@media (max-width: 800px)': {
        maxWidth: '15px',
      },
    },
  },
  secondaryRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    justifyContent: 'space-between',
    width: '-webkit-fill-available',
    '@media (max-width: 375px)': {
      flexDirection: 'column',
    },
  },
  title: {
    fontSize: '1.715rem',
    '@media (max-width: 300px)': {
      fontSize: '1rem',
    },
  },
  notActiveTitle: {
    fontSize: '1.715rem',
    color: colors.secondaryLightGrey,
    '@media (max-width: 300px)': {
      fontSize: '1rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    justifyContent: 'flex-end',
    width: '-webkit-fill-available',
    marginTop: '20px',
    alignItems: 'flex-start',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  separator: {
    borderTop: `2px solid ${colors.primaryDarkGrey}`,
    margin: '25px 0px',
  },
  progressBarContainer: {
    margin: '20px 0px 10px',
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    margin: '5px',
  },
})
