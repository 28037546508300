import daisyTokenGreenUpdate from 'assets/icons/daisyTokenGreenUpdate.svg'
import { useStyles } from './styles'

export const AmountWithLogo = ({ amount }: { amount: string }): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.row}>
      <span className={classes.info}>{amount}</span>
      <img src={daisyTokenGreenUpdate} alt="daisyTokenIcon" />
    </div>
  )
}
