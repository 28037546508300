/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CONFIRM_FEE_LIMIT, FEE_LIMIT } from 'assets/constants'
import { TokenInfo } from 'core/interfaces/hooks/trc20/TokenInfo'
import { Transaction, TransactionStatus } from 'core/interfaces/services/Transaction'
import { RequestAccounts } from 'core/interfaces/services/Tron'
import { TronWeb } from 'tronweb-typings'
import { RequestDelayUtils } from 'utils/tron/RequestDelayUtils'
import { BN, fromBNish } from 'utils/tsUtils'

export type FunctionParams = {
  type?: string | number | string[] | number[]
  value?: string | number | string[] | number[]
}

async function getTokenInfo(tronWeb: TronWeb, contractAddress: string): Promise<TokenInfo> {
  await RequestDelayUtils.addDelay()
  const contract = await tronWeb.contract().at(contractAddress)
  const name = await contract.name().call()
  const symbol = await contract.symbol().call()
  const decimals = await contract.decimals().call()
  return { name, symbol, decimals }
}

async function tryWithdraw(tronWeb: any, contractAddress: string): Promise<string> {
  await RequestDelayUtils.addDelay()
  return await trySendTransaction(tronWeb, contractAddress, FEE_LIMIT, 'withdraw()')
}

async function getBalance(tronWeb: any, account: string): Promise<BN> {
  await RequestDelayUtils.addDelay()
  const _balance: unknown = await tronWeb.trx.getBalance(account)
  return fromBNish(`${_balance}`)
}

async function getTransactionStatus(tronWeb: any, transaction: Transaction): Promise<TransactionStatus> {
  await RequestDelayUtils.addDelay()
  const tx = await tronWeb.trx.getTransactionInfo(transaction.hash)

  switch (true) {
    case Object.keys(tx).length === 0:
      throw new Error('Empty transaction')
    case tx.receipt.result === 'SUCCESS':
      return 'success'
    case tx.result === 'FAILED':
      return 'error'
    default:
      return 'unknown'
  }
}

async function getRequestAccounts(tronWeb: any): Promise<RequestAccounts> {
  await RequestDelayUtils.addDelay()
  return await tronWeb.request({ method: 'tron_requestAccounts' })
}

async function tryCompletePurchase(tronWeb: any, contractAddress: string): Promise<string> {
  await RequestDelayUtils.addDelay()
  return await trySendTransaction(tronWeb, contractAddress, CONFIRM_FEE_LIMIT, 'completePurchase()')
}

async function callMethod(
  tronWeb: any,
  contractAddress: string,
  feeLimit: string,
  functionSelector: string,
  parameters: FunctionParams[] = [],
): Promise<string> {
  const options = { feeLimit }
  const issuerAddress = tronWeb.defaultAddress.hex
  const transactionObject = await tronWeb.transactionBuilder.triggerConstantContract(
    contractAddress,
    functionSelector,
    options,
    parameters,
    issuerAddress,
  )
  return transactionObject.constant_result[0]
}

async function trySendTransaction(
  tronWeb: any,
  contractAddress: string,
  feeLimit: string,
  functionSelector: string,
  parameters: FunctionParams[] = [],
): Promise<string> {
  const options = { feeLimit, callValue: 0 }
  const issuerAddress = tronWeb.defaultAddress.base58
  const transactionObject = await tronWeb.transactionBuilder.triggerSmartContract(
    contractAddress,
    functionSelector,
    options,
    parameters,
    issuerAddress,
  )

  if (!transactionObject.result?.result) {
    throw new Error(`Unknown error: ${transactionObject}`)
  }

  const signedTransaction = await tronWeb.trx.sign(transactionObject.transaction)
  if (!signedTransaction.signature) {
    throw new Error('Transaction was not signed properly')
  }

  const tx = await tronWeb.trx.sendRawTransaction(signedTransaction)
  const txId = tx?.transaction?.txID
  if (typeof txId !== 'string') {
    throw new Error(`Invalid transaction format for ${txId}`)
  }
  return txId
}

export const TronWebAPI = {
  getTokenInfo,
  getBalance,
  getTransactionStatus,
  getRequestAccounts,
  tryWithdraw,
  tryCompletePurchase,
  callMethod,
  trySendTransaction,
}
