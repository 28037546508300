import { DAISY_PRECISION, MIN_TRX_SWAP, TRX_PRECISION } from 'assets/constants'
import { SwapStateEnum } from 'core/enums/components/buttons/swapButton/SwapStakeEnum'

import { TFunction } from 'react-i18next'
import { BN, formattedDecimals } from 'utils/tsUtils'

export function swapValidateState(balanceInTRX: BN, balanceInDaisy: BN, inputValue: BN): SwapStateEnum {
  if (inputValue.isZero()) {
    return SwapStateEnum.ERROR_INPUT_VALUE_IS_ZERO
  }
  if (balanceInTRX.lt(MIN_TRX_SWAP)) {
    return SwapStateEnum.ERROR_INSUFFICIENT_TRX_BALANCE
  }
  if (balanceInDaisy.lt(inputValue)) {
    return SwapStateEnum.ERROR_INSUFFICIENT_DAISY_BALANCE
  }
  return SwapStateEnum.SUCCESS
}

export function swapStateErrorMessage(state: SwapStateEnum, t: TFunction<'translation'>, inputValue: BN): string {
  switch (state) {
    case SwapStateEnum.ERROR_INSUFFICIENT_TRX_BALANCE:
      return t('buttons_error_insufficient_trx_balance', {
        amount: formattedDecimals(MIN_TRX_SWAP, TRX_PRECISION),
      })
    case SwapStateEnum.ERROR_INSUFFICIENT_DAISY_BALANCE:
      return t('buttons_error_insufficient_daisy_user_balance', {
        amount: formattedDecimals(inputValue, DAISY_PRECISION),
      })
    case SwapStateEnum.ERROR_INPUT_VALUE_IS_ZERO:
      return t('buttons_error_stake_is_zero')
    case SwapStateEnum.SUCCESS:
    default:
      return ''
  }
}
