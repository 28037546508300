import { DAISY_PRECISION, MIN_TRX_APPROVE, TRX_PRECISION } from 'assets/constants'
import { TFunction } from 'react-i18next'
import { BN, formattedDecimals } from 'utils/tsUtils'

export enum ApproveStateEnum {
  SUCCESS,
  ERROR_INSUFFICIENT_TRX_BALANCE,
  ERROR_INSUFFICIENT_DAISY_BALANCE,
}

export function approveValidateState(balanceInTRX: BN, balanceInDAISY: BN, inputValue: BN): ApproveStateEnum {
  if (balanceInDAISY.lt(inputValue)) {
    return ApproveStateEnum.ERROR_INSUFFICIENT_DAISY_BALANCE
  }
  if (balanceInTRX.lt(MIN_TRX_APPROVE)) {
    return ApproveStateEnum.ERROR_INSUFFICIENT_TRX_BALANCE
  }
  return ApproveStateEnum.SUCCESS
}

export function approveStateErrorMessage(state: ApproveStateEnum, inputValue: BN, t: TFunction<'translation'>): string {
  switch (state) {
    case ApproveStateEnum.ERROR_INSUFFICIENT_DAISY_BALANCE:
      return t('buttons_error_insufficient_daisy_user_balance', {
        amount: formattedDecimals(inputValue, DAISY_PRECISION),
      })
    case ApproveStateEnum.ERROR_INSUFFICIENT_TRX_BALANCE:
      return t('buttons_error_insufficient_trx_balance', {
        amount: formattedDecimals(MIN_TRX_APPROVE, TRX_PRECISION),
      })
    case ApproveStateEnum.SUCCESS:
    default:
      return ''
  }
}
