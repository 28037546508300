/* eslint-disable @typescript-eslint/no-explicit-any */
import { TronWebAPI } from 'api/tron/TronAPI'
import { LocalStorageKeysEnum } from 'core/enums/services/LocalStorageKeysEnum'
import { ToastStateEnum } from 'core/enums/utils/ToastStateEnum'
import {
  Transaction,
  TransactionActionDetails,
  TransactionContextProps,
  TransactionFilterOptions,
  TransactionStatus,
} from 'core/interfaces/services/Transaction'
import useLocalStorage from 'hooks/useLocalStorage'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toastStateMessage } from 'utils/toastUtils'
import { getLocalStorageName } from 'utils/transformLocalStorage'
import {
  DECLINED_BY_USER,
  TRANSACTION_CACHE_LIMITATION_COUNT,
  TRON_WEB_ENVIRONMENTS_NOT_FOUND,
} from '../assets/constants'
import { useTronWeb } from './AccountService'
import { useRefresh } from './RefreshContextProvider'

const TransactionContext = React.createContext<TransactionContextProps>({
  transactions: [],
  addTransaction: () => void 0,
  clearTransactions: () => void 0,
  hasPendingTransactions: () => false,
  filterTransactions: () => [],
  registerTransaction: () => void 0,
  handleTransactionError: () => void 0,
})

const useTransactionService = (): TransactionContextProps => ({ ...useContext(TransactionContext) })

const runningRequests: Record<string, Promise<TransactionStatus>> = {}

let transactions: Transaction[] = []

// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic refresh
const TransactionContextProvider = ({ children }: { children: JSX.Element[] }): JSX.Element => {
  const { t } = useTranslation()
  const { tronWeb, chain, account } = useTronWeb()
  const { fastRefresh } = useRefresh()
  const transactionCacheKey = getLocalStorageName(LocalStorageKeysEnum.TRANSACTION_CACHE)
  const [transactionCache, setTransactionCache] = useLocalStorage<Transaction[]>(transactionCacheKey, [])

  useEffect(() => void (transactions = transactionCache), [])

  useEffect(() => setTransactionCache(transactions.slice(-TRANSACTION_CACHE_LIMITATION_COUNT)), [transactions])

  useEffect(() => {
    if (!tronWeb || !account) {
      return
    }

    const options = { withToast: true }

    filterTransactions({ statuses: ['unknown'] }).forEach(
      async (transaction) => await updateTransactionStatus(tronWeb, transaction, options),
    )

    return () => void (options.withToast = false)
  }, [fastRefresh, tronWeb, account, chain])

  async function updateTransactionStatus(
    tronWeb: any,
    transaction: Transaction,
    { withToast }: { withToast: boolean },
  ): Promise<void> {
    if (!runningRequests[transaction.hash]) {
      runningRequests[transaction.hash] = TronWebAPI.getTransactionStatus(tronWeb, transaction)
    }

    let { status } = transaction
    try {
      status = await runningRequests[transaction.hash]
      addTransaction({ ...transaction, status })
    } catch (e) {
      console.error('getTransactionInfo', e)
    }

    delete runningRequests[transaction.hash]
    if (withToast) {
      showToast(status)
    }
  }

  function showToast(status: TransactionStatus): void {
    switch (status) {
      case 'success':
        toastStateMessage(ToastStateEnum.SUCCESS, t('toast_transaction_success'))
        break
      case 'error':
        toastStateMessage(ToastStateEnum.ERROR, t('toast_transaction_failed'))
    }
  }

  const isCurrentAccountTransaction = useCallback(
    (t: Transaction) => t.account.toLowerCase() === account?.toLowerCase() && t.chain === chain,
    [chain, account],
  )

  const accountTransactions = useMemo(
    () => transactions.filter(isCurrentAccountTransaction),
    [transactions, account, chain],
  )

  const addTransaction = useCallback(
    (transaction: Transaction) => {
      const transactionIndex = transactions.findIndex((tx) => tx.hash === transaction.hash)
      const _transactions = [...transactions]

      if (transactionIndex !== -1) {
        _transactions[transactionIndex] = transaction
      } else {
        _transactions.push(transaction)
      }
      transactions = _transactions
    },
    [transactions],
  )

  const registerTransaction = useCallback(
    (hash: string, action: TransactionActionDetails) => {
      if (!chain || !account) {
        throw TRON_WEB_ENVIRONMENTS_NOT_FOUND
      }

      const newTransaction: Transaction = { hash, account, chain, status: 'unknown', action }
      addTransaction(newTransaction)
    },
    [addTransaction, account, chain],
  )

  const clearTransactions = useCallback(
    () => (transactions = transactions.filter((transaction) => !isCurrentAccountTransaction(transaction))),
    [transactions, account, chain],
  )

  const hasPendingTransactions = useCallback(
    () => accountTransactions.some((transaction) => transaction.status === 'unknown'),
    [accountTransactions],
  )

  const filterTransactions = useCallback(
    ({ statuses, actions }: TransactionFilterOptions) =>
      accountTransactions.filter(
        (tx) =>
          (!statuses?.length || statuses.includes(tx.status)) &&
          (!actions?.length || actions.includes(tx.action.action)),
      ),
    [accountTransactions],
  )

  const handleTransactionError = useCallback((error: unknown): void => {
    if (error === DECLINED_BY_USER) {
      return
    }
    toastStateMessage(ToastStateEnum.ERROR, t('toast_transaction_failed'))
  }, [])

  const providedValue: TransactionContextProps = {
    transactions: accountTransactions,
    addTransaction,
    clearTransactions,
    hasPendingTransactions,
    filterTransactions,
    registerTransaction,
    handleTransactionError,
  }
  return <TransactionContext.Provider value={providedValue}>{children}</TransactionContext.Provider>
}

export { TransactionContextProvider, TransactionContext, useTransactionService }
