import { makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from 'utils/mui'
import { IsShownStyleProps } from 'core/interfaces/components/modals/attentionBlock/styles/IsShownStyleProps'

export const useStyles = makeStyles<Theme, IsShownStyleProps>({
  root: {
    position: 'absolute',
    display: ({ isShown }) => (isShown ? 'flex' : 'none'),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.primaryDarkGrey,
    width: 'max-content',
    height: '44px',
    borderEndEndRadius: '5px',
    borderEndStartRadius: '5px',
    padding: '0px 22px',
    zIndex: 100,
    boxShadow: `0px 4px 10px ${colors.black_opacity_0_55}`,
    '@media (max-width: 850px)': {
      right: '12px',
      left: '12px',
      width: 'auto',
      justifyContent: 'space-between',
    },
  },
  primaryTitle: {
    fontSize: '1rem',
    color: colors.white,
    '@media (max-width: 500px)': {
      fontSize: '0.7rem',
    },
  },
  secondaryTitle: {
    fontSize: '1rem',
    fontWeight: 500,
    color: colors.primaryGreen,
    textDecoration: 'none',
  },
  cross: {
    border: `1px solid ${colors.white}`,
    background: 'transparent',
    width: '19px',
    height: '19px',
    padding: 0,
    borderRadius: '50%',
    marginLeft: '10px',
    cursor: 'pointer',
  },
})
