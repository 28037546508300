import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0',
    '@media (max-width: 991px)': {
      marginTop: '40px',
      padding: '20px 0',
    },
    '@media (max-width: 767px)': {
      marginTop: '20px',
    },
  },
  bannerContainer: {
    maxWidth: '720px',
    minHeight: '366px',
    padding: '40px',
    background: colors.black,
    borderRadius: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: colors.white,
    textAlign: 'center',
    '@media (max-width: 991px)': {
      padding: '30px 40px',
      minHeight: '200px',
    },
  },
  titleWrapper: {
    '& span': {
      fontSize: '2.25rem',
      '@media (max-width: 991px)': {
        fontSize: '2rem',
      },
      '@media (max-width: 767px)': {
        fontSize: '1.75rem',
      },
    },
  },
  primaryTitle: {
    color: colors.primaryGreen,
  },
})
