import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Layout } from 'components/layout'
import { ClaimSwitch } from 'pages/claimSwitch'
import { ExchangeSwitch } from 'pages/exchangeSwitch'
import { LiquidPromoSwitch } from 'pages/liquidPromo'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CLAIM, EXCHANGE, LIQUID_APP } from 'routes'
import { AccountContextProvider } from 'services/AccountService'
import 'services/i18n'
import { RefreshContextProvider } from 'services/RefreshContextProvider'
import { TransactionContextProvider } from 'services/TransactionService'

import { themeConfig } from 'utils/mui'

const App = (): JSX.Element => {
  const theme = createTheme(themeConfig)

  return (
    <AccountContextProvider>
      <RefreshContextProvider>
        <MuiThemeProvider theme={theme}>
          <TransactionContextProvider>
            <Routing />
            <ToastContainer />
          </TransactionContextProvider>
        </MuiThemeProvider>
      </RefreshContextProvider>
    </AccountContextProvider>
  )
}

const Routing = (): JSX.Element => {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path={CLAIM} component={ClaimSwitch} />
          <Route exact path={EXCHANGE} component={ExchangeSwitch} />
          <Route exact path={LIQUID_APP} component={LiquidPromoSwitch} />
          <Redirect to={CLAIM} />
        </Switch>
      </Layout>
    </Router>
  )
}

export default App
