/* eslint-disable @typescript-eslint/no-explicit-any */
import { TronWebAPI } from 'api/tron/TronAPI'
import { ChainEnum } from 'core/enums/services/ChainEnum'
import { WalletConnectionStatusEnum } from 'core/enums/services/WalletConnectionStatusEnum'
import { RequestAccounts, TronWebDetails, TronWebNode } from 'core/interfaces/services/Tron'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { TronWeb } from 'tronweb-typings'

function getWalletConnectionStatus(status: WalletConnectionStatusEnum): WalletConnectionStatusEnum {
  switch (status) {
    case WalletConnectionStatusEnum.SUCCESS:
    case WalletConnectionStatusEnum.ERROR_RESUBMIT:
    case WalletConnectionStatusEnum.ERROR_USER_REJECTED_REQUEST:
      return status
    default:
      return WalletConnectionStatusEnum.UNKNOWN
  }
}

function getChain(host?: string): ChainEnum | undefined {
  switch (host) {
    case 'https://api.trongrid.io':
    case 'https://api.tronstack.io':
      return ChainEnum.MAINNET
    case 'https://api.shasta.trongrid.io':
      return ChainEnum.SHASTA
    default:
      return undefined
  }
}

const AccountContext = React.createContext<TronWebDetails>({
  account: undefined,
  chain: undefined,
  tronWeb: undefined,
  walletConnectionStatus: WalletConnectionStatusEnum.UNKNOWN,
  tryOpenTronAuthWindow: async () => void 0,
  isLoggedIn: false,
})

export const useTronWeb = (): TronWebDetails => ({ ...useContext(AccountContext) })

export const AccountContextProvider = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const [tronWeb, setTronWeb] = useState<TronWeb | undefined>(window.tronWeb)

  const initialSolidityNode = (window.tronWeb as TronWebNode)?.solidityNode?.host
  const [chain, setChain] = useState<ChainEnum | undefined>(getChain(initialSolidityNode))

  const initialAddress = (window.tronWeb as TronWebNode)?.defaultAddress?.base58 || undefined
  const [account, setAccount] = useState<string | undefined>(initialAddress)

  const [walletConnectionStatus, setWalletConnectionStatus] = useState<WalletConnectionStatusEnum>(
    WalletConnectionStatusEnum.UNKNOWN,
  )
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

  const tryOpenTronAuthWindow = useCallback(async (): Promise<void> => {
    if (!tronWeb) {
      return
    }
    try {
      await TronWebAPI.getRequestAccounts(tronWeb)
    } catch (error) {
      console.error('AccountService(tryOpenTronAuthWindow):', error)
    }
  }, [tronWeb])

  function updateNode(solidityNode?: string): void {
    setChain(getChain(solidityNode))
  }

  function onPossibleChanges(): void {
    const newAddress = window.tronWeb?.defaultAddress?.base58 || undefined
    const newSolidityNode = (window.tronWeb as TronWebNode)?.solidityNode?.host

    setTronWeb(window.tronWeb)
    updateNode(newSolidityNode)
    setAccount(newAddress)
  }

  function onNewConnection(payload?: { address?: string; isAuth?: boolean; node?: { solidityNode?: string } }): void {
    const newAddress = payload?.address || window.tronWeb?.defaultAddress?.base58 || undefined
    const newSolidityNode = payload?.node?.solidityNode || (window.tronWeb as TronWebNode)?.solidityNode?.host

    setTronWeb(window.tronWeb)
    updateNode(newSolidityNode)
    setAccount(newAddress)
  }

  function onDisconnect(): void {
    updateNode(undefined)
    setAccount(undefined)
  }

  function onMessage(e: MessageEvent): void {
    const isValidTronWebEvent = !e?.data?.isTronLink || !e?.data?.message?.action
    if (isValidTronWebEvent || !window.tronWeb) {
      return
    }

    // TODO: add event action enum and interfaces for possible event payload
    // update wallet connection status
    if (e.data.message.data?.data?.code) {
      setWalletConnectionStatus(getWalletConnectionStatus(e.data.message.data?.data?.code))
    }

    switch (e.data.message.action) {
      case 'acceptWeb':
      case 'connectWeb':
      case 'tabReply': // init tronWeb
        onNewConnection(e.data.message.data?.data)
        break
      case 'setNode': // node changed
        updateNode(e.data.message.data?.node?.solidityNode)
        break
      case 'accountsChanged': // account switched
        setIsLoggedIn(!!e.data.message.data?.address)
        setAccount(e.data.message.data?.address || undefined)
        break
      case 'setAccount': // check auth status
        setIsLoggedIn(!!e.data.message.data?.address)
        break
      case 'connect': // on connect
        onPossibleChanges()
        break
      case 'disconnect':
      case 'disconnectWeb': // on disconnect
        setAccount(undefined)
        break
    }
  }

  // update wallet status on account switch
  async function checkWalletStatus(tronWeb: any, account?: string) {
    if (!tronWeb || !account) {
      return
    }

    // TronLink DApp workaround
    if (!tronWeb.request) {
      setWalletConnectionStatus(WalletConnectionStatusEnum.SUCCESS)
      return
    }

    try {
      const isActive: RequestAccounts = await TronWebAPI.getRequestAccounts(tronWeb)
      setWalletConnectionStatus(getWalletConnectionStatus(isActive.code))
    } catch (error) {
      console.error('AccountService', error)
    }
  }

  useEffect(() => {
    // TronLink DApp workaround
    onPossibleChanges()
    setIsLoggedIn(!!window.tronWeb?.defaultAddress?.base58)

    window.addEventListener('message', onMessage)
    return () => window.removeEventListener('message', onMessage)
  }, [])

  useEffect(() => {
    window.addEventListener('offline', onDisconnect)
    return () => window.removeEventListener('offline', onDisconnect)
  }, [])

  useEffect(() => {
    window.addEventListener('online', onPossibleChanges)
    return () => window.removeEventListener('online', onPossibleChanges)
  }, [])

  useEffect(() => void checkWalletStatus(tronWeb, account), [tronWeb, account])

  // show tron auth window on page load if user is not authenticated
  let hasAttemptOfAuthentication = false
  const openConnectWalletWindowOnInit = useCallback(async (isLoggedIn: boolean) => {
    if (!isLoggedIn && !hasAttemptOfAuthentication) {
      await tryOpenTronAuthWindow()
    }
    hasAttemptOfAuthentication = true
  }, [])
  useEffect(() => void (!!tronWeb && openConnectWalletWindowOnInit(!!account)), [tronWeb, account])

  const providedValue = {
    account,
    chain,
    tronWeb,
    walletConnectionStatus,
    tryOpenTronAuthWindow,
    isLoggedIn,
  }
  return <AccountContext.Provider value={providedValue}>{children}</AccountContext.Provider>
}
