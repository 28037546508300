import { TransactionActionDetails } from 'core/interfaces/services/Transaction'
import { TFunction } from 'react-i18next'

export const actionToText = (t: TFunction<'translation'>, action: TransactionActionDetails): string => {
  switch (action.action) {
    case 'claim':
      return t('tx_action_claim')
    case 'confirm':
      return t('tx_action_confirm')
    case 'referrerConfirm':
      return t('tx_action_referrer_confirm')
    case 'approveExchange':
      return t('tx_action_approve')
    case 'confirmAddress':
      return t('tx_action_confirm_near_address')
    case 'exchange':
      return t('tx_action_swap_lift')
    default:
      return ''
  }
}
