import { TokenExchangeAPI } from 'api/tron/TokenExchangeAPI'
import { useStrictTron } from 'hooks/strict/common/useStrictTron'
import { useCallback } from 'react'
import { useTransactionService } from 'services/TransactionService'
import { BN } from 'utils/tsUtils'

type ExchangeTransactionProps = (amount: BN) => Promise<void>

export function useExchange(contractAddress: string): ExchangeTransactionProps {
  const { tronWeb } = useStrictTron()
  const { registerTransaction, handleTransactionError } = useTransactionService()

  const request: ExchangeTransactionProps = async (amount) => {
    try {
      const hash = await TokenExchangeAPI.exchange(tronWeb, contractAddress, amount)
      registerTransaction(hash, { action: 'exchange', amount: amount.toString() })
    } catch (error: unknown) {
      console.error('useExchange', error)
      handleTransactionError(error)
    }
  }
  return useCallback<ExchangeTransactionProps>(request, [tronWeb, contractAddress, registerTransaction])
}
