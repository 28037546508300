import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 0px 100px',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  skeleton: {
    minHeight: '180px',
    minWidth: '450px',
    maxWidth: '720px',
    padding: '33px 35px',
    marginBottom: '30px',
    '@media (max-width: 600px)': {
      minWidth: '-webkit-fill-available',
    },
  },
})
