import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'utils/mui'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: '80px',
    zIndex: 10,
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  navBarContainer: {
    width: '35vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  ulList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  liItem: {
    margin: '10px',
  },
  navLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    transition: 'all 0.4s',
    textDecoration: 'none',
    alignItems: 'center',
    width: 'max-content',

    '&>span': {
      color: colors.white,
      opacity: 0.5,
      marginRight: '6px',
      fontSize: '1rem',
      transition: 'all 0.4s',
    },
    '&:hover': {
      opacity: 0.8,
    },
  },
  navLinkActiveContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    transition: 'all 0.4s',
    textDecoration: 'none',
    width: 'max-content',
    '&>span': {
      color: colors.white,
      opacity: 1,
      marginRight: '6px',
      fontSize: '1.13rem',
    },
    '&:hover': {
      opacity: 0.6,
    },
  },
  centerColumnWithOutButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '-webkit-fill-available',
    marginTop: '-70px',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 960px)': {
      marginTop: '0px',
    },
  },
  rootWithOutButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexDirection: 'column',
    padding: '40px 42px 20px 30px',
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
  },
  linkText: {
    fontSize: '1.15rem',
    cursor: 'pointer',
    '&:hover': {
      '&.MuiLink-underlineHover': {
        textDecoration: 'underline',
      },
    },
  },
  maxWidth: {
    width: '470px',
    '@media (max-width: 1300px)': {
      width: '420px',
    },
    '@media (max-width: 800px)': {
      width: 'auto',
    },
  },
  unConnectedButton: {
    minWidth: '172px',
    fontSize: '1rem',
    borderRadius: '50px',
    background: colors.primaryPurple,
    color: colors.white,
    height: '48px',
    justifyContent: 'space-around',
    '&:hover': {
      background: colors.primaryPurple,
    },
    '@media (max-width: 600px)': {
      fontSize: '0.85rem',
      height: '100%',
    },
    '@media (max-width: 320px)': {
      display: 'none',
    },
  },
  outerArrow: {
    width: '10px',
    height: '10px',
    marginLeft: '5px',
  },
})
